export default {
  account: () => import("/workspace/layouts/account.vue").then(m => m.default || m),
  admin: () => import("/workspace/layouts/admin.vue").then(m => m.default || m),
  app: () => import("/workspace/layouts/app.vue").then(m => m.default || m),
  auth: () => import("/workspace/layouts/auth.vue").then(m => m.default || m),
  client: () => import("/workspace/layouts/client.vue").then(m => m.default || m),
  dashboard: () => import("/workspace/layouts/dashboard.vue").then(m => m.default || m),
  default: () => import("/workspace/layouts/default.vue").then(m => m.default || m),
  main: () => import("/workspace/layouts/main.vue").then(m => m.default || m),
  onboarding: () => import("/workspace/layouts/onboarding.vue").then(m => m.default || m),
  print: () => import("/workspace/layouts/print.vue").then(m => m.default || m),
  provider: () => import("/workspace/layouts/provider.vue").then(m => m.default || m),
  "provider-new-claim": () => import("/workspace/layouts/provider/NewClaim.vue").then(m => m.default || m)
}