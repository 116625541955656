import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/vue";
// import { createRouter } from "vue-router";
import type { Router } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const vueRouter = nuxtApp.$router as Router;

  const config = useRuntimeConfig();

  Sentry.init({
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    environment: config.public.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter),
      }),
      // new Sentry.Replay(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
