export const usePartnerStore = defineStore("partnerStore", () => {
    // State
    const partner = ref(null);

    // const updateAccountIsActive = ref(false);
    // const modalAddClientIsActive = ref(false);
    // const modalAddInsuranceIsActive = ref(false);

    // Getters
    const partnerId = computed(() => {
        return partner.value?.id || null;
    });

    // Actions

    function $reset() {
        partner.value = null;
    }

    return {
        partner,
        partnerId,

        $reset,
    };
});
