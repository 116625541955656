<template>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
            <p class="text-base font-semibold text-indigo-600">{{ error.statusCode }}</p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{ error.statusMessage }}</h1>
            <p class="mt-6 text-base leading-7 text-gray-600">{{ error.message }}</p>
            <p v-if="config.public.NODE_ENV != 'production'" class="mt-6 text-base leading-7 text-gray-600">{{ error.data }}
            </p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <UButton size="lg" to="/">Go back home</UButton>
                <UButton size="lg" variant="soft" to="mailto:hello@nomadrx.co" icon="i-heroicons-arrow-right" trailing>
                    Contact support
                </UButton>
            </div>
        </div>
    </main>
</template>

<script setup>
const config = useRuntimeConfig()

const props = defineProps({
    error: Object
})

useHead({
    htmlAttrs: {
        class: "h-full",
    },
    bodyAttrs: {
        class: "h-full",
    },
});

</script>