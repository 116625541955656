import type { CookieRef } from "#app";

import {
    isSignInWithEmailLink,
    signInWithEmailLink,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    onIdTokenChanged,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, doc } from "firebase/firestore";
import { type User } from "firebase/auth";
import { type Auth } from "firebase/auth";
import { type Firestore } from "firebase/firestore";

export function useFirebaseAuth() {
    const { $getAuth, $router } = useNuxtApp();
    const auth: Auth = $getAuth as Auth;

    const authStore: any = useAuthStore(); // Uncommenting these lines crash the app (blank page). Why???
    // authStore.authStateLoaded = false; // Uncommenting these lines crash the app (blank page). Why???
    const {
        isAuthenticated,
        isClient,
        isPartner,
        hasAccountAccess,
        hasPartnerAccess,
    } = storeToRefs(authStore);
    const userStore = useUserStore();

    const userCookie: CookieRef<User | undefined | null> =
        useCookie("userCookie"); // Option 1 to have it here
    // const userCookie = useCookie("userCookie", { httpOnly: true }) // would make cookie httpOnly

    const initUser = () => {
        auth.authStateReady().then(() => {
            authStore.$patch({ authStateReady: true });
        });

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                authStore.authUser = user;
                const decodedToken = await auth.currentUser?.getIdTokenResult();
                authStore.sbUserId = decodedToken.claims?.sb_user_id;

                await authStore.authenticateSupabaseUser();
                await authStore.authenticateUser();
            } else {
                authStore.authUser = null;
                authStore.clearAuth();
            }
            userCookie.value = user ? user : null;
            authStore.authStateLoaded = user ? true : false;
        });

        onIdTokenChanged(auth, async (user) => {
            console.log("user: ", user);
            userCookie.value = user ? user : null;
            authStore.authUser = user ? user : null;
        });
    };

    const createUserEmailPassword = async (email: string, password: string) => {
        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {})
            .catch((error) => {
                throw createError({
                    statusCode: 401,
                    statusMessage: error.message,
                    cause: error,
                });
            });
    };

    const checkIfSignInWithEmailLink = () => {
        return isSignInWithEmailLink(auth, window.location.href);
    };

    const signInWithLinkAndEmail = async (email: string) => {
        const url = window.location.href;
        return await signInWithEmailLink(auth, email, url);
    };

    const signInUserWithEmailAndPassword = async (
        email: string,
        password: string
    ) => {
        return await signInWithEmailAndPassword(auth, email, password);
    };

    const signOutUser = async () => {
        console.log("signOutUser");
        const authStore = useAuthStore();
        const userStore = useUserStore();
        const accountStore = useAccountStore();
        const accountBillingStore = useAccountBillingStore();

        try {
            await auth.signOut();
            authStore.$reset();
            userStore.$reset();
            accountStore.$reset();
            accountBillingStore.$reset();
            await navigateTo({ path: "/" });
        } catch (error) {}
    };

    const getFirebaseIdToken = async (forceRefresh = false) => {
        try {
            return await auth.currentUser?.getIdToken(forceRefresh);
        } catch (error) {
            throw createError(error);
        }
    };

    return {
        signOutUser,
        initUser,
        createUserEmailPassword,
        checkIfSignInWithEmailLink,
        signInWithLinkAndEmail,
        signInUserWithEmailAndPassword,
        getFirebaseIdToken,
    };
}

export function useFirestoreDb() {
    const { $firestore } = useNuxtApp();
    const firestore: Firestore = $firestore as Firestore;
    const getNewDocumentId = async () => {
        const newDocumentRef = doc(collection(firestore, "documents"));
        return newDocumentRef.id;
    };

    return {
        getNewDocumentId,
    };
}
