export const useUserStore = defineStore("userStore", () => {
    // State
    const user = ref(null);
    const updateUserIsActive = ref(false);

    // Getters
    const accountId = computed(() => {
        return user.value?.account_id || null;
    });

    // Actions
    async function fetchUser(reload?: boolean) {
        console.log("fetchUser");
        const authStore = useAuthStore();
        const sbUserId = authStore.sbUserId;
        console.log("sbUserId: ", sbUserId);

        if (user.value && !reload) return;

        const data: any = await $fetchWithToken(`/api/v4/user/${sbUserId}`);
        user.value = null;
        user.value = data;
    }

    function $reset() {
        user.value = null;
    }

    return {
        user,
        accountId,
        updateUserIsActive,
        fetchUser,
        $reset,
    };
});
