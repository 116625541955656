import { defineNuxtPlugin } from "#app";
import VueGtag from "vue-gtag-next";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    VueGtag,
    {
      property: {
        id: "G-P5M1F63D4P",
      },
      appName: "NomadRx",
      enabled: true,
      pageTrackerScreenviewEnabled: true,
    },
    nuxtApp.vueApp.router
  );
});
