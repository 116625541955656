import { default as indexjC24MPf1kzMeta } from "/workspace/pages/about/index.vue?macro=true";
import { default as indexyiFIPQq7DmMeta } from "/workspace/pages/account/billing/index.vue?macro=true";
import { default as indexthjdbToXyFMeta } from "/workspace/pages/account/claims/index.vue?macro=true";
import { default as indexeEKJArLYGsMeta } from "/workspace/pages/account/index.vue?macro=true";
import { default as indexpayn1OCds8Meta } from "/workspace/pages/account/insurance/index.vue?macro=true";
import { default as index4OHI8fcswVMeta } from "/workspace/pages/account/providers/index.vue?macro=true";
import { default as indexlscRnTHTMEMeta } from "/workspace/pages/account/settings/index.vue?macro=true";
import { default as accountBoGQzqSbHbMeta } from "/workspace/pages/account.vue?macro=true";
import { default as _91claim_id_93o606wt2BbBMeta } from "/workspace/pages/admin/claims/[claim_id].vue?macro=true";
import { default as indexRo6gOS5ib9Meta } from "/workspace/pages/admin/claims/index.vue?macro=true";
import { default as claimsD60w0ykpW0Meta } from "/workspace/pages/admin/claims.vue?macro=true";
import { default as indexq75YD7qrjYMeta } from "/workspace/pages/admin/claimsbyinsurance/index.vue?macro=true";
import { default as _91client_id_93OFNlECppXvMeta } from "/workspace/pages/admin/clients/[client_id].vue?macro=true";
import { default as indexFl5XsfIUmTMeta } from "/workspace/pages/admin/clients/index.vue?macro=true";
import { default as clientsYpRhsnhbptMeta } from "/workspace/pages/admin/clients.vue?macro=true";
import { default as indexpnWTDfA6sWMeta } from "/workspace/pages/admin/eob/[eob_id]/index.vue?macro=true";
import { default as indexz8I7u1qLK3Meta } from "/workspace/pages/admin/eob/index.vue?macro=true";
import { default as _91event_id_93N1kXqaoncPMeta } from "/workspace/pages/admin/events/[event_id].vue?macro=true";
import { default as index4tbuEWOJ3YMeta } from "/workspace/pages/admin/events/index.vue?macro=true";
import { default as index8x3i9klHioMeta } from "/workspace/pages/admin/index.vue?macro=true";
import { default as _91insurance_id_93dCMbSZWxpPMeta } from "/workspace/pages/admin/insurance/[insurance_id].vue?macro=true";
import { default as _91insurancecompany_id_93DeT9G4wKX3Meta } from "/workspace/pages/admin/insurancecompanies/[insurancecompany_id].vue?macro=true";
import { default as indexPGSPZTuUjUMeta } from "/workspace/pages/admin/insurancecompanies/index.vue?macro=true";
import { default as indexR1qFMWvMClMeta } from "/workspace/pages/admin/invoices/index.vue?macro=true";
import { default as _91partner_id_93EZLhJfxRYgMeta } from "/workspace/pages/admin/partners/[partner_id].vue?macro=true";
import { default as indexEhbXh5e9e8Meta } from "/workspace/pages/admin/partners/index.vue?macro=true";
import { default as partnersLxCbavIyaEMeta } from "/workspace/pages/admin/partners.vue?macro=true";
import { default as indexkSbCE2DiA3Meta } from "/workspace/pages/admin/payments/[payment_id]/index.vue?macro=true";
import { default as paymentqBL55cf1XWMeta } from "/workspace/pages/admin/payments/add/payment.vue?macro=true";
import { default as payoutmrBXN4VVf9Meta } from "/workspace/pages/admin/payments/add/payout.vue?macro=true";
import { default as indexWqx06a0LMeMeta } from "/workspace/pages/admin/payments/index.vue?macro=true";
import { default as indexvXvV1kZAFQMeta } from "/workspace/pages/admin/payout-items/index.vue?macro=true";
import { default as indexfUKumScy56Meta } from "/workspace/pages/admin/payouts/[payout_id]/index.vue?macro=true";
import { default as add5eKpdKsr1cMeta } from "/workspace/pages/admin/payouts/add.vue?macro=true";
import { default as indexUchLdHrAqOMeta } from "/workspace/pages/admin/payouts/index.vue?macro=true";
import { default as _91transaction_id_93y4N1mSKpdoMeta } from "/workspace/pages/admin/transactions/[transaction_id].vue?macro=true";
import { default as indexcgqUXUeijLMeta } from "/workspace/pages/admin/transactions/index.vue?macro=true";
import { default as index_oldzpXNdq08LdMeta } from "/workspace/pages/admin/users/index_old.vue?macro=true";
import { default as index7Q2JOnQTqrMeta } from "/workspace/pages/admin/users/index.vue?macro=true";
import { default as admintMKYXMmtvkMeta } from "/workspace/pages/admin.vue?macro=true";
import { default as _91id_93tgQ6cxaFKDMeta } from "/workspace/pages/app/claims/[id].vue?macro=true";
import { default as indexxGcBCWmLirMeta } from "/workspace/pages/app/claims/index.vue?macro=true";
import { default as claims65vDBaHAjcMeta } from "/workspace/pages/app/claims.vue?macro=true";
import { default as indexRiYfQhK2LcMeta } from "/workspace/pages/app/index.vue?macro=true";
import { default as _91id_93mM9lQhIi22Meta } from "/workspace/pages/app/payouts/[id].vue?macro=true";
import { default as indextCbL8WIwFrMeta } from "/workspace/pages/app/payouts/index.vue?macro=true";
import { default as payoutsZ4bPSnnxhaMeta } from "/workspace/pages/app/payouts.vue?macro=true";
import { default as indexSmPcnqHjuyMeta } from "/workspace/pages/app/settings/index.vue?macro=true";
import { default as settingsehtu8sJ7otMeta } from "/workspace/pages/app/settings.vue?macro=true";
import { default as appTw7atrXt9bMeta } from "/workspace/pages/app.vue?macro=true";
import { default as indexmlSXcZRmpxMeta } from "/workspace/pages/blog/[categorySlug]/[postSlug]/index.vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as indexMi0KGM8ZPOMeta } from "/workspace/pages/client/billing/index.vue?macro=true";
import { default as indexHZgmU4MuhRMeta } from "/workspace/pages/client/claims/[claim_id]/index.vue?macro=true";
import { default as indexyx0yD43MZ3Meta } from "/workspace/pages/client/claims/index.vue?macro=true";
import { default as indexIT6ViVzxbiMeta } from "/workspace/pages/client/index.vue?macro=true";
import { default as indexknmlx7xwfBMeta } from "/workspace/pages/client/insurance/index.vue?macro=true";
import { default as indexkh5BRUooISMeta } from "/workspace/pages/client/providers/index.vue?macro=true";
import { default as indexCWiFRsm0wGMeta } from "/workspace/pages/client/settings/index.vue?macro=true";
import { default as clientZ4qxjB9WE5Meta } from "/workspace/pages/client.vue?macro=true";
import { default as addressesjzvlDehsZcMeta } from "/workspace/pages/dashboard/accounts/[id]/addresses.vue?macro=true";
import { default as billingOBMDZv9MwtMeta } from "/workspace/pages/dashboard/accounts/[id]/billing.vue?macro=true";
import { default as clientsWW9ehfJ57UMeta } from "/workspace/pages/dashboard/accounts/[id]/clients.vue?macro=true";
import { default as indexyX0WFBVRpgMeta } from "/workspace/pages/dashboard/accounts/[id]/index.vue?macro=true";
import { default as usersKLoI3HEeCpMeta } from "/workspace/pages/dashboard/accounts/[id]/users.vue?macro=true";
import { default as _91id_93mgskJbq1fqMeta } from "/workspace/pages/dashboard/accounts/[id].vue?macro=true";
import { default as indexDISNErhJrUMeta } from "/workspace/pages/dashboard/accounts/index.vue?macro=true";
import { default as accountsfvRKzPSAwkMeta } from "/workspace/pages/dashboard/accounts.vue?macro=true";
import { default as indexVdJUDHNhFhMeta } from "/workspace/pages/dashboard/addresses/index.vue?macro=true";
import { default as indexh5QD4El8fgMeta } from "/workspace/pages/dashboard/auth/[id]/index.vue?macro=true";
import { default as indexkDytVfeFAZMeta } from "/workspace/pages/dashboard/auth/index.vue?macro=true";
import { default as indexNutcNQfsULMeta } from "/workspace/pages/dashboard/claims/[id]/index.vue?macro=true";
import { default as invoiceEA71pV1pB0Meta } from "/workspace/pages/dashboard/claims/[id]/invoice.vue?macro=true";
import { default as paymentsBGsWTjCZZAMeta } from "/workspace/pages/dashboard/claims/[id]/payments.vue?macro=true";
import { default as printMuMdhZfwdcMeta } from "/workspace/pages/dashboard/claims/[id]/print.vue?macro=true";
import { default as _91id_93WWB9ZP9zmLMeta } from "/workspace/pages/dashboard/claims/[id].vue?macro=true";
import { default as indexW59pxRUqmDMeta } from "/workspace/pages/dashboard/claims/index.vue?macro=true";
import { default as claims8UzpXkD7zNMeta } from "/workspace/pages/dashboard/claims.vue?macro=true";
import { default as indexJFqmVtBmsuMeta } from "/workspace/pages/dashboard/clients/[id]/index.vue?macro=true";
import { default as indexROEunKH4ApMeta } from "/workspace/pages/dashboard/clients/index.vue?macro=true";
import { default as clientsEseddXbLLRMeta } from "/workspace/pages/dashboard/clients.vue?macro=true";
import { default as index2lrBtCOh90Meta } from "/workspace/pages/dashboard/index.vue?macro=true";
import { default as indexRsEkqpOExzMeta } from "/workspace/pages/dashboard/insurance-payment-items/index.vue?macro=true";
import { default as index74NXVCY2uXMeta } from "/workspace/pages/dashboard/insurance-payments/[id]/index.vue?macro=true";
import { default as indextzRhIs7MbbMeta } from "/workspace/pages/dashboard/insurance-payments/index.vue?macro=true";
import { default as insurance_45paymentsS4BrsUGHTPMeta } from "/workspace/pages/dashboard/insurance-payments.vue?macro=true";
import { default as indexqzKJ9aTecXMeta } from "/workspace/pages/dashboard/insurance/[id]/index.vue?macro=true";
import { default as indexT3rqnGxdQZMeta } from "/workspace/pages/dashboard/insurance/index.vue?macro=true";
import { default as indexG0QZfIOKfDMeta } from "/workspace/pages/dashboard/numo-companies/[id]/index.vue?macro=true";
import { default as indexL6ktIwT8TsMeta } from "/workspace/pages/dashboard/numo-companies/index.vue?macro=true";
import { default as numo_45companiesFBPniJBaV1Meta } from "/workspace/pages/dashboard/numo-companies.vue?macro=true";
import { default as indexM7OX4NNg7mMeta } from "/workspace/pages/dashboard/numo-invoices/index.vue?macro=true";
import { default as indexwgFd6KWhOvMeta } from "/workspace/pages/dashboard/partners/[id]/index.vue?macro=true";
import { default as payoutsuL4oJp7YCRMeta } from "/workspace/pages/dashboard/partners/[id]/payouts.vue?macro=true";
import { default as productshTlTOWNgB5Meta } from "/workspace/pages/dashboard/partners/[id]/products.vue?macro=true";
import { default as providers7PV3gxkDZqMeta } from "/workspace/pages/dashboard/partners/[id]/providers.vue?macro=true";
import { default as _91id_9391icsgpYOSMeta } from "/workspace/pages/dashboard/partners/[id].vue?macro=true";
import { default as indexFtMwrN63UgMeta } from "/workspace/pages/dashboard/partners/index.vue?macro=true";
import { default as partnersp4cpYQ5Pm1Meta } from "/workspace/pages/dashboard/partners.vue?macro=true";
import { default as indexI4g2FBmKekMeta } from "/workspace/pages/dashboard/payment-items/index.vue?macro=true";
import { default as index7QiRiy80KdMeta } from "/workspace/pages/dashboard/payments-client/[id]/index.vue?macro=true";
import { default as indexy5Hg7ehFH9Meta } from "/workspace/pages/dashboard/payments-client/index.vue?macro=true";
import { default as indexxruUTUAtEvMeta } from "/workspace/pages/dashboard/payments/[id]/index.vue?macro=true";
import { default as indexd95mMhV6DZMeta } from "/workspace/pages/dashboard/payments/index.vue?macro=true";
import { default as paymentsyh4b7pknHLMeta } from "/workspace/pages/dashboard/payments.vue?macro=true";
import { default as indexQXnq7Gcy9OMeta } from "/workspace/pages/dashboard/payout-items/index.vue?macro=true";
import { default as index8ITPabIyrCMeta } from "/workspace/pages/dashboard/payouts/[id]/index.vue?macro=true";
import { default as indexpNhBYRpF8XMeta } from "/workspace/pages/dashboard/payouts/index.vue?macro=true";
import { default as payouts3nVadYxOwoMeta } from "/workspace/pages/dashboard/payouts.vue?macro=true";
import { default as indexjA4rcI035oMeta } from "/workspace/pages/dashboard/policy/index.vue?macro=true";
import { default as indexlVGRvEkYBOMeta } from "/workspace/pages/dashboard/providers/index.vue?macro=true";
import { default as indexqAQZJMeuohMeta } from "/workspace/pages/dashboard/users-fb/[id]/index.vue?macro=true";
import { default as indexXjank1jTf8Meta } from "/workspace/pages/dashboard/users-fb/index.vue?macro=true";
import { default as indexa7zSI429KXMeta } from "/workspace/pages/dashboard/users/[id]/index.vue?macro=true";
import { default as indexY6hBUUdN4MMeta } from "/workspace/pages/dashboard/users/index.vue?macro=true";
import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as _91city_id_932jux7r4bRsMeta } from "/workspace/pages/find-care/[city_id].vue?macro=true";
import { default as indexMHaTGPSBJdMeta } from "/workspace/pages/find/[country]/[city]/[insurance]/index.vue?macro=true";
import { default as indexUEw2kz0IXrMeta } from "/workspace/pages/find/[country]/[city]/index.vue?macro=true";
import { default as indexXjHepd63zcMeta } from "/workspace/pages/find/[country]/index.vue?macro=true";
import { default as indexpQ8UTyU8aaMeta } from "/workspace/pages/find/index.vue?macro=true";
import { default as indexxtUpaPOwALMeta } from "/workspace/pages/for-providers/index.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as indexdEfuMmlaquMeta } from "/workspace/pages/login-verify/index.vue?macro=true";
import { default as indextTkPG1jUYQMeta } from "/workspace/pages/login/index.vue?macro=true";
import { default as _91partner_slug_93wEKnrLzytPMeta } from "/workspace/pages/onboard/[partner_slug].vue?macro=true";
import { default as indexqaRTieiQTAMeta } from "/workspace/pages/onboard/billing/index.vue?macro=true";
import { default as indexep2FVeuO1hMeta } from "/workspace/pages/onboard/card-upload/index.vue?macro=true";
import { default as index7pvcQ4WbaeMeta } from "/workspace/pages/onboard/clients/index.vue?macro=true";
import { default as indexekDEk6fbD7Meta } from "/workspace/pages/onboard/eligibility/index.vue?macro=true";
import { default as indexzAVvhj0a33Meta } from "/workspace/pages/onboard/index.vue?macro=true";
import { default as indexk4RQNAn1A2Meta } from "/workspace/pages/onboard/insurance/index.vue?macro=true";
import { default as onboard8CtHldbEa1Meta } from "/workspace/pages/onboard.vue?macro=true";
import { default as _91partner_slug_93PudgRMACp8Meta } from "/workspace/pages/onboard2/[partner_slug].vue?macro=true";
import { default as indexdvwnXYrSqIMeta } from "/workspace/pages/onboard2/index.vue?macro=true";
import { default as afspaBpOzMkNnYMMeta } from "/workspace/pages/print/claim/[claim_id]/invoice/afspa.vue?macro=true";
import { default as indexVhHsHl5loxMeta } from "/workspace/pages/print/claim/[claim_id]/invoice/index.vue?macro=true";
import { default as va_45fmp45JDc1wnikMeta } from "/workspace/pages/print/claim/[claim_id]/invoice/va-fmp.vue?macro=true";
import { default as bank_45info0vJ72NeQEuMeta } from "/workspace/pages/print/partner/[partner_id]/bank-info.vue?macro=true";
import { default as index21M5iF70M6Meta } from "/workspace/pages/privacy-policy/index.vue?macro=true";
import { default as indexgWykPGTeWyMeta } from "/workspace/pages/provider/-claims_old/[claim_id]/index.vue?macro=true";
import { default as invoiceOtAApTA8x1Meta } from "/workspace/pages/provider/-claims_old/[claim_id]/invoice.vue?macro=true";
import { default as indexP2cYlQUY2jMeta } from "/workspace/pages/provider/-claims_old/index.vue?macro=true";
import { default as _91selection_93GnBvNMW40CMeta } from "/workspace/pages/provider/-payouts_old/list/[selection].vue?macro=true";
import { default as indextU0vjecuRKMeta } from "/workspace/pages/provider/-payouts_old/payout/[payout_id]/index.vue?macro=true";
import { default as editBNCCmJAIUbMeta } from "/workspace/pages/provider/claims/[claim_id]/edit.vue?macro=true";
import { default as indexMA9sINlfhBMeta } from "/workspace/pages/provider/claims/[claim_id]/index.vue?macro=true";
import { default as _91claim_id_9361TzopFA0DMeta } from "/workspace/pages/provider/claims/claim/[claim_id].vue?macro=true";
import { default as indexUrPiHdwzypMeta } from "/workspace/pages/provider/claims/index.vue?macro=true";
import { default as _91selection_93C93NS6PZwjMeta } from "/workspace/pages/provider/claims/list/[selection].vue?macro=true";
import { default as newcF451ih8nwMeta } from "/workspace/pages/provider/claims/new.vue?macro=true";
import { default as _91client_id_93kxrFej6VohMeta } from "/workspace/pages/provider/clients-list/[client_id].vue?macro=true";
import { default as indexqRNyRdrsgjMeta } from "/workspace/pages/provider/clients-list/index.vue?macro=true";
import { default as indexVqAiCRL7juMeta } from "/workspace/pages/provider/clients/[client_id]/index.vue?macro=true";
import { default as _91claim_id_93oNnc4cUAZoMeta } from "/workspace/pages/provider/clients/client/[client_id]/claim/[claim_id].vue?macro=true";
import { default as indexi2TqUycvaZMeta } from "/workspace/pages/provider/clients/client/[client_id]/claim/index.vue?macro=true";
import { default as claims79FqQlL2KEMeta } from "/workspace/pages/provider/clients/client/[client_id]/claims.vue?macro=true";
import { default as documentsxdUtdYck8DMeta } from "/workspace/pages/provider/clients/client/[client_id]/documents.vue?macro=true";
import { default as indexUI5cz6jZkgMeta } from "/workspace/pages/provider/clients/client/[client_id]/index.vue?macro=true";
import { default as profileovvEC0WU7lMeta } from "/workspace/pages/provider/clients/client/[client_id]/profile.vue?macro=true";
import { default as servicesCkH5zlhIQ9Meta } from "/workspace/pages/provider/clients/client/[client_id]/services.vue?macro=true";
import { default as _91client_id_933ZDI9uIh2KMeta } from "/workspace/pages/provider/clients/client/[client_id].vue?macro=true";
import { default as _91selection_93CkmU6HW7WxMeta } from "/workspace/pages/provider/clients/list/[selection].vue?macro=true";
import { default as indexQFvE4umZKsMeta } from "/workspace/pages/provider/clients/onboard/index.vue?macro=true";
import { default as indexs7ql82qALeMeta } from "/workspace/pages/provider/dashboard/index.vue?macro=true";
import { default as indexttU3t6KIDyMeta } from "/workspace/pages/provider/index.vue?macro=true";
import { default as _91insurance_id_931KqgejHqNsMeta } from "/workspace/pages/provider/insurance/[insurance_id].vue?macro=true";
import { default as _91invoice_id_93nzh0zNKC02Meta } from "/workspace/pages/provider/invoices/[invoice_id].vue?macro=true";
import { default as indexWeSrR0wGrZMeta } from "/workspace/pages/provider/invoices/index.vue?macro=true";
import { default as indexl3Xq7qfXj9Meta } from "/workspace/pages/provider/notifications/index.vue?macro=true";
import { default as _91selection_937FmODMqbQcMeta } from "/workspace/pages/provider/payments/list/[selection].vue?macro=true";
import { default as index41NMszAI79Meta } from "/workspace/pages/provider/payments/payment/[payment_id]/index.vue?macro=true";
import { default as indexmiljkZmdxMMeta } from "/workspace/pages/provider/payouts/[payout_id]/index.vue?macro=true";
import { default as index1n0P0gKs28Meta } from "/workspace/pages/provider/payouts/index.vue?macro=true";
import { default as indexetRYwnOkRkMeta } from "/workspace/pages/provider/profile/index.vue?macro=true";
import { default as _91provider_id_93RcYw175uK0Meta } from "/workspace/pages/provider/providers/[provider_id].vue?macro=true";
import { default as indexceVaDNURJvMeta } from "/workspace/pages/provider/providers/index.vue?macro=true";
import { default as indexKrqpFAHfXfMeta } from "/workspace/pages/provider/reports/index.vue?macro=true";
import { default as index9BPUkSiXt5Meta } from "/workspace/pages/provider/services/index.vue?macro=true";
import { default as indexfpHyeVEyBOMeta } from "/workspace/pages/provider/settings/index.vue?macro=true";
import { default as indexBvS6rp05y4Meta } from "/workspace/pages/provider/user/index.vue?macro=true";
import { default as providerxTIlQH7m3VMeta } from "/workspace/pages/provider.vue?macro=true";
import { default as indexcPdLjFv6ymMeta } from "/workspace/pages/providers/[slug]/index.vue?macro=true";
import { default as indexEgNGqByi03Meta } from "/workspace/pages/sb-auth/confirm/index.vue?macro=true";
import { default as indexgPppCmCO0TMeta } from "/workspace/pages/sb-auth/login/index.vue?macro=true";
import { default as indexzSnIsKkEd9Meta } from "/workspace/pages/signup/index.vue?macro=true";
import { default as index9xmF4TLFhBMeta } from "/workspace/pages/terms-of-use/index.vue?macro=true";
export default [
  {
    name: indexjC24MPf1kzMeta?.name ?? "about",
    path: indexjC24MPf1kzMeta?.path ?? "/about",
    meta: indexjC24MPf1kzMeta || {},
    alias: indexjC24MPf1kzMeta?.alias || [],
    redirect: indexjC24MPf1kzMeta?.redirect,
    component: () => import("/workspace/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: accountBoGQzqSbHbMeta?.name ?? undefined,
    path: accountBoGQzqSbHbMeta?.path ?? "/account",
    meta: accountBoGQzqSbHbMeta || {},
    alias: accountBoGQzqSbHbMeta?.alias || [],
    redirect: accountBoGQzqSbHbMeta?.redirect,
    component: () => import("/workspace/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: indexyiFIPQq7DmMeta?.name ?? "account-billing",
    path: indexyiFIPQq7DmMeta?.path ?? "billing",
    meta: indexyiFIPQq7DmMeta || {},
    alias: indexyiFIPQq7DmMeta?.alias || [],
    redirect: indexyiFIPQq7DmMeta?.redirect,
    component: () => import("/workspace/pages/account/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexthjdbToXyFMeta?.name ?? "account-claims",
    path: indexthjdbToXyFMeta?.path ?? "claims",
    meta: indexthjdbToXyFMeta || {},
    alias: indexthjdbToXyFMeta?.alias || [],
    redirect: indexthjdbToXyFMeta?.redirect,
    component: () => import("/workspace/pages/account/claims/index.vue").then(m => m.default || m)
  },
  {
    name: indexeEKJArLYGsMeta?.name ?? "account",
    path: indexeEKJArLYGsMeta?.path ?? "",
    meta: indexeEKJArLYGsMeta || {},
    alias: indexeEKJArLYGsMeta?.alias || [],
    redirect: indexeEKJArLYGsMeta?.redirect,
    component: () => import("/workspace/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexpayn1OCds8Meta?.name ?? "account-insurance",
    path: indexpayn1OCds8Meta?.path ?? "insurance",
    meta: indexpayn1OCds8Meta || {},
    alias: indexpayn1OCds8Meta?.alias || [],
    redirect: indexpayn1OCds8Meta?.redirect,
    component: () => import("/workspace/pages/account/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: index4OHI8fcswVMeta?.name ?? "account-providers",
    path: index4OHI8fcswVMeta?.path ?? "providers",
    meta: index4OHI8fcswVMeta || {},
    alias: index4OHI8fcswVMeta?.alias || [],
    redirect: index4OHI8fcswVMeta?.redirect,
    component: () => import("/workspace/pages/account/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexlscRnTHTMEMeta?.name ?? "account-settings",
    path: indexlscRnTHTMEMeta?.path ?? "settings",
    meta: indexlscRnTHTMEMeta || {},
    alias: indexlscRnTHTMEMeta?.alias || [],
    redirect: indexlscRnTHTMEMeta?.redirect,
    component: () => import("/workspace/pages/account/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admintMKYXMmtvkMeta?.name ?? undefined,
    path: admintMKYXMmtvkMeta?.path ?? "/admin",
    meta: admintMKYXMmtvkMeta || {},
    alias: admintMKYXMmtvkMeta?.alias || [],
    redirect: admintMKYXMmtvkMeta?.redirect,
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: claimsD60w0ykpW0Meta?.name ?? undefined,
    path: claimsD60w0ykpW0Meta?.path ?? "claims",
    meta: claimsD60w0ykpW0Meta || {},
    alias: claimsD60w0ykpW0Meta?.alias || [],
    redirect: claimsD60w0ykpW0Meta?.redirect,
    component: () => import("/workspace/pages/admin/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91claim_id_93o606wt2BbBMeta?.name ?? "admin-claims-claim_id",
    path: _91claim_id_93o606wt2BbBMeta?.path ?? ":claim_id()",
    meta: _91claim_id_93o606wt2BbBMeta || {},
    alias: _91claim_id_93o606wt2BbBMeta?.alias || [],
    redirect: _91claim_id_93o606wt2BbBMeta?.redirect,
    component: () => import("/workspace/pages/admin/claims/[claim_id].vue").then(m => m.default || m)
  },
  {
    name: indexRo6gOS5ib9Meta?.name ?? "admin-claims",
    path: indexRo6gOS5ib9Meta?.path ?? "",
    meta: indexRo6gOS5ib9Meta || {},
    alias: indexRo6gOS5ib9Meta?.alias || [],
    redirect: indexRo6gOS5ib9Meta?.redirect,
    component: () => import("/workspace/pages/admin/claims/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexq75YD7qrjYMeta?.name ?? "admin-claimsbyinsurance",
    path: indexq75YD7qrjYMeta?.path ?? "claimsbyinsurance",
    meta: indexq75YD7qrjYMeta || {},
    alias: indexq75YD7qrjYMeta?.alias || [],
    redirect: indexq75YD7qrjYMeta?.redirect,
    component: () => import("/workspace/pages/admin/claimsbyinsurance/index.vue").then(m => m.default || m)
  },
  {
    name: clientsYpRhsnhbptMeta?.name ?? undefined,
    path: clientsYpRhsnhbptMeta?.path ?? "clients",
    meta: clientsYpRhsnhbptMeta || {},
    alias: clientsYpRhsnhbptMeta?.alias || [],
    redirect: clientsYpRhsnhbptMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients.vue").then(m => m.default || m),
    children: [
  {
    name: _91client_id_93OFNlECppXvMeta?.name ?? "admin-clients-client_id",
    path: _91client_id_93OFNlECppXvMeta?.path ?? ":client_id()",
    meta: _91client_id_93OFNlECppXvMeta || {},
    alias: _91client_id_93OFNlECppXvMeta?.alias || [],
    redirect: _91client_id_93OFNlECppXvMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients/[client_id].vue").then(m => m.default || m)
  },
  {
    name: indexFl5XsfIUmTMeta?.name ?? "admin-clients",
    path: indexFl5XsfIUmTMeta?.path ?? "",
    meta: indexFl5XsfIUmTMeta || {},
    alias: indexFl5XsfIUmTMeta?.alias || [],
    redirect: indexFl5XsfIUmTMeta?.redirect,
    component: () => import("/workspace/pages/admin/clients/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpnWTDfA6sWMeta?.name ?? "admin-eob-eob_id",
    path: indexpnWTDfA6sWMeta?.path ?? "eob/:eob_id()",
    meta: indexpnWTDfA6sWMeta || {},
    alias: indexpnWTDfA6sWMeta?.alias || [],
    redirect: indexpnWTDfA6sWMeta?.redirect,
    component: () => import("/workspace/pages/admin/eob/[eob_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexz8I7u1qLK3Meta?.name ?? "admin-eob",
    path: indexz8I7u1qLK3Meta?.path ?? "eob",
    meta: indexz8I7u1qLK3Meta || {},
    alias: indexz8I7u1qLK3Meta?.alias || [],
    redirect: indexz8I7u1qLK3Meta?.redirect,
    component: () => import("/workspace/pages/admin/eob/index.vue").then(m => m.default || m)
  },
  {
    name: _91event_id_93N1kXqaoncPMeta?.name ?? "admin-events-event_id",
    path: _91event_id_93N1kXqaoncPMeta?.path ?? "events/:event_id()",
    meta: _91event_id_93N1kXqaoncPMeta || {},
    alias: _91event_id_93N1kXqaoncPMeta?.alias || [],
    redirect: _91event_id_93N1kXqaoncPMeta?.redirect,
    component: () => import("/workspace/pages/admin/events/[event_id].vue").then(m => m.default || m)
  },
  {
    name: index4tbuEWOJ3YMeta?.name ?? "admin-events",
    path: index4tbuEWOJ3YMeta?.path ?? "events",
    meta: index4tbuEWOJ3YMeta || {},
    alias: index4tbuEWOJ3YMeta?.alias || [],
    redirect: index4tbuEWOJ3YMeta?.redirect,
    component: () => import("/workspace/pages/admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: index8x3i9klHioMeta?.name ?? "admin",
    path: index8x3i9klHioMeta?.path ?? "",
    meta: index8x3i9klHioMeta || {},
    alias: index8x3i9klHioMeta?.alias || [],
    redirect: index8x3i9klHioMeta?.redirect,
    component: () => import("/workspace/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91insurance_id_93dCMbSZWxpPMeta?.name ?? "admin-insurance-insurance_id",
    path: _91insurance_id_93dCMbSZWxpPMeta?.path ?? "insurance/:insurance_id()",
    meta: _91insurance_id_93dCMbSZWxpPMeta || {},
    alias: _91insurance_id_93dCMbSZWxpPMeta?.alias || [],
    redirect: _91insurance_id_93dCMbSZWxpPMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurance/[insurance_id].vue").then(m => m.default || m)
  },
  {
    name: _91insurancecompany_id_93DeT9G4wKX3Meta?.name ?? "admin-insurancecompanies-insurancecompany_id",
    path: _91insurancecompany_id_93DeT9G4wKX3Meta?.path ?? "insurancecompanies/:insurancecompany_id()",
    meta: _91insurancecompany_id_93DeT9G4wKX3Meta || {},
    alias: _91insurancecompany_id_93DeT9G4wKX3Meta?.alias || [],
    redirect: _91insurancecompany_id_93DeT9G4wKX3Meta?.redirect,
    component: () => import("/workspace/pages/admin/insurancecompanies/[insurancecompany_id].vue").then(m => m.default || m)
  },
  {
    name: indexPGSPZTuUjUMeta?.name ?? "admin-insurancecompanies",
    path: indexPGSPZTuUjUMeta?.path ?? "insurancecompanies",
    meta: indexPGSPZTuUjUMeta || {},
    alias: indexPGSPZTuUjUMeta?.alias || [],
    redirect: indexPGSPZTuUjUMeta?.redirect,
    component: () => import("/workspace/pages/admin/insurancecompanies/index.vue").then(m => m.default || m)
  },
  {
    name: indexR1qFMWvMClMeta?.name ?? "admin-invoices",
    path: indexR1qFMWvMClMeta?.path ?? "invoices",
    meta: indexR1qFMWvMClMeta || {},
    alias: indexR1qFMWvMClMeta?.alias || [],
    redirect: indexR1qFMWvMClMeta?.redirect,
    component: () => import("/workspace/pages/admin/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: partnersLxCbavIyaEMeta?.name ?? undefined,
    path: partnersLxCbavIyaEMeta?.path ?? "partners",
    meta: partnersLxCbavIyaEMeta || {},
    alias: partnersLxCbavIyaEMeta?.alias || [],
    redirect: partnersLxCbavIyaEMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners.vue").then(m => m.default || m),
    children: [
  {
    name: _91partner_id_93EZLhJfxRYgMeta?.name ?? "admin-partners-partner_id",
    path: _91partner_id_93EZLhJfxRYgMeta?.path ?? ":partner_id()",
    meta: _91partner_id_93EZLhJfxRYgMeta || {},
    alias: _91partner_id_93EZLhJfxRYgMeta?.alias || [],
    redirect: _91partner_id_93EZLhJfxRYgMeta?.redirect,
    component: () => import("/workspace/pages/admin/partners/[partner_id].vue").then(m => m.default || m)
  },
  {
    name: indexEhbXh5e9e8Meta?.name ?? "admin-partners",
    path: indexEhbXh5e9e8Meta?.path ?? "",
    meta: indexEhbXh5e9e8Meta || {},
    alias: indexEhbXh5e9e8Meta?.alias || [],
    redirect: indexEhbXh5e9e8Meta?.redirect,
    component: () => import("/workspace/pages/admin/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkSbCE2DiA3Meta?.name ?? "admin-payments-payment_id",
    path: indexkSbCE2DiA3Meta?.path ?? "payments/:payment_id()",
    meta: indexkSbCE2DiA3Meta || {},
    alias: indexkSbCE2DiA3Meta?.alias || [],
    redirect: indexkSbCE2DiA3Meta?.redirect,
    component: () => import("/workspace/pages/admin/payments/[payment_id]/index.vue").then(m => m.default || m)
  },
  {
    name: paymentqBL55cf1XWMeta?.name ?? "admin-payments-add-payment",
    path: paymentqBL55cf1XWMeta?.path ?? "payments/add/payment",
    meta: paymentqBL55cf1XWMeta || {},
    alias: paymentqBL55cf1XWMeta?.alias || [],
    redirect: paymentqBL55cf1XWMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments/add/payment.vue").then(m => m.default || m)
  },
  {
    name: payoutmrBXN4VVf9Meta?.name ?? "admin-payments-add-payout",
    path: payoutmrBXN4VVf9Meta?.path ?? "payments/add/payout",
    meta: payoutmrBXN4VVf9Meta || {},
    alias: payoutmrBXN4VVf9Meta?.alias || [],
    redirect: payoutmrBXN4VVf9Meta?.redirect,
    component: () => import("/workspace/pages/admin/payments/add/payout.vue").then(m => m.default || m)
  },
  {
    name: indexWqx06a0LMeMeta?.name ?? "admin-payments",
    path: indexWqx06a0LMeMeta?.path ?? "payments",
    meta: indexWqx06a0LMeMeta || {},
    alias: indexWqx06a0LMeMeta?.alias || [],
    redirect: indexWqx06a0LMeMeta?.redirect,
    component: () => import("/workspace/pages/admin/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexvXvV1kZAFQMeta?.name ?? "admin-payout-items",
    path: indexvXvV1kZAFQMeta?.path ?? "payout-items",
    meta: indexvXvV1kZAFQMeta || {},
    alias: indexvXvV1kZAFQMeta?.alias || [],
    redirect: indexvXvV1kZAFQMeta?.redirect,
    component: () => import("/workspace/pages/admin/payout-items/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUKumScy56Meta?.name ?? "admin-payouts-payout_id",
    path: indexfUKumScy56Meta?.path ?? "payouts/:payout_id()",
    meta: indexfUKumScy56Meta || {},
    alias: indexfUKumScy56Meta?.alias || [],
    redirect: indexfUKumScy56Meta?.redirect,
    component: () => import("/workspace/pages/admin/payouts/[payout_id]/index.vue").then(m => m.default || m)
  },
  {
    name: add5eKpdKsr1cMeta?.name ?? "admin-payouts-add",
    path: add5eKpdKsr1cMeta?.path ?? "payouts/add",
    meta: add5eKpdKsr1cMeta || {},
    alias: add5eKpdKsr1cMeta?.alias || [],
    redirect: add5eKpdKsr1cMeta?.redirect,
    component: () => import("/workspace/pages/admin/payouts/add.vue").then(m => m.default || m)
  },
  {
    name: indexUchLdHrAqOMeta?.name ?? "admin-payouts",
    path: indexUchLdHrAqOMeta?.path ?? "payouts",
    meta: indexUchLdHrAqOMeta || {},
    alias: indexUchLdHrAqOMeta?.alias || [],
    redirect: indexUchLdHrAqOMeta?.redirect,
    component: () => import("/workspace/pages/admin/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: _91transaction_id_93y4N1mSKpdoMeta?.name ?? "admin-transactions-transaction_id",
    path: _91transaction_id_93y4N1mSKpdoMeta?.path ?? "transactions/:transaction_id()",
    meta: _91transaction_id_93y4N1mSKpdoMeta || {},
    alias: _91transaction_id_93y4N1mSKpdoMeta?.alias || [],
    redirect: _91transaction_id_93y4N1mSKpdoMeta?.redirect,
    component: () => import("/workspace/pages/admin/transactions/[transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexcgqUXUeijLMeta?.name ?? "admin-transactions",
    path: indexcgqUXUeijLMeta?.path ?? "transactions",
    meta: indexcgqUXUeijLMeta || {},
    alias: indexcgqUXUeijLMeta?.alias || [],
    redirect: indexcgqUXUeijLMeta?.redirect,
    component: () => import("/workspace/pages/admin/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: index_oldzpXNdq08LdMeta?.name ?? "admin-users-index_old",
    path: index_oldzpXNdq08LdMeta?.path ?? "users/index_old",
    meta: index_oldzpXNdq08LdMeta || {},
    alias: index_oldzpXNdq08LdMeta?.alias || [],
    redirect: index_oldzpXNdq08LdMeta?.redirect,
    component: () => import("/workspace/pages/admin/users/index_old.vue").then(m => m.default || m)
  },
  {
    name: index7Q2JOnQTqrMeta?.name ?? "admin-users",
    path: index7Q2JOnQTqrMeta?.path ?? "users",
    meta: index7Q2JOnQTqrMeta || {},
    alias: index7Q2JOnQTqrMeta?.alias || [],
    redirect: index7Q2JOnQTqrMeta?.redirect,
    component: () => import("/workspace/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: appTw7atrXt9bMeta?.name ?? undefined,
    path: appTw7atrXt9bMeta?.path ?? "/app",
    meta: appTw7atrXt9bMeta || {},
    alias: appTw7atrXt9bMeta?.alias || [],
    redirect: appTw7atrXt9bMeta?.redirect,
    component: () => import("/workspace/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: claims65vDBaHAjcMeta?.name ?? undefined,
    path: claims65vDBaHAjcMeta?.path ?? "claims",
    meta: claims65vDBaHAjcMeta || {},
    alias: claims65vDBaHAjcMeta?.alias || [],
    redirect: claims65vDBaHAjcMeta?.redirect,
    component: () => import("/workspace/pages/app/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93tgQ6cxaFKDMeta?.name ?? "app-claims-id",
    path: _91id_93tgQ6cxaFKDMeta?.path ?? ":id()",
    meta: _91id_93tgQ6cxaFKDMeta || {},
    alias: _91id_93tgQ6cxaFKDMeta?.alias || [],
    redirect: _91id_93tgQ6cxaFKDMeta?.redirect,
    component: () => import("/workspace/pages/app/claims/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxGcBCWmLirMeta?.name ?? "app-claims",
    path: indexxGcBCWmLirMeta?.path ?? "",
    meta: indexxGcBCWmLirMeta || {},
    alias: indexxGcBCWmLirMeta?.alias || [],
    redirect: indexxGcBCWmLirMeta?.redirect,
    component: () => import("/workspace/pages/app/claims/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexRiYfQhK2LcMeta?.name ?? "app",
    path: indexRiYfQhK2LcMeta?.path ?? "",
    meta: indexRiYfQhK2LcMeta || {},
    alias: indexRiYfQhK2LcMeta?.alias || [],
    redirect: indexRiYfQhK2LcMeta?.redirect,
    component: () => import("/workspace/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: payoutsZ4bPSnnxhaMeta?.name ?? undefined,
    path: payoutsZ4bPSnnxhaMeta?.path ?? "payouts",
    meta: payoutsZ4bPSnnxhaMeta || {},
    alias: payoutsZ4bPSnnxhaMeta?.alias || [],
    redirect: payoutsZ4bPSnnxhaMeta?.redirect,
    component: () => import("/workspace/pages/app/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93mM9lQhIi22Meta?.name ?? "app-payouts-id",
    path: _91id_93mM9lQhIi22Meta?.path ?? ":id()",
    meta: _91id_93mM9lQhIi22Meta || {},
    alias: _91id_93mM9lQhIi22Meta?.alias || [],
    redirect: _91id_93mM9lQhIi22Meta?.redirect,
    component: () => import("/workspace/pages/app/payouts/[id].vue").then(m => m.default || m)
  },
  {
    name: indextCbL8WIwFrMeta?.name ?? "app-payouts",
    path: indextCbL8WIwFrMeta?.path ?? "",
    meta: indextCbL8WIwFrMeta || {},
    alias: indextCbL8WIwFrMeta?.alias || [],
    redirect: indextCbL8WIwFrMeta?.redirect,
    component: () => import("/workspace/pages/app/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsehtu8sJ7otMeta?.name ?? undefined,
    path: settingsehtu8sJ7otMeta?.path ?? "settings",
    meta: settingsehtu8sJ7otMeta || {},
    alias: settingsehtu8sJ7otMeta?.alias || [],
    redirect: settingsehtu8sJ7otMeta?.redirect,
    component: () => import("/workspace/pages/app/settings.vue").then(m => m.default || m),
    children: [
  {
    name: indexSmPcnqHjuyMeta?.name ?? "app-settings",
    path: indexSmPcnqHjuyMeta?.path ?? "",
    meta: indexSmPcnqHjuyMeta || {},
    alias: indexSmPcnqHjuyMeta?.alias || [],
    redirect: indexSmPcnqHjuyMeta?.redirect,
    component: () => import("/workspace/pages/app/settings/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexmlSXcZRmpxMeta?.name ?? "blog-categorySlug-postSlug",
    path: indexmlSXcZRmpxMeta?.path ?? "/blog/:categorySlug()/:postSlug()",
    meta: indexmlSXcZRmpxMeta || {},
    alias: indexmlSXcZRmpxMeta?.alias || [],
    redirect: indexmlSXcZRmpxMeta?.redirect,
    component: () => import("/workspace/pages/blog/[categorySlug]/[postSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRgrKfU8NaHMeta?.name ?? "blog",
    path: indexRgrKfU8NaHMeta?.path ?? "/blog",
    meta: indexRgrKfU8NaHMeta || {},
    alias: indexRgrKfU8NaHMeta?.alias || [],
    redirect: indexRgrKfU8NaHMeta?.redirect,
    component: () => import("/workspace/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: clientZ4qxjB9WE5Meta?.name ?? undefined,
    path: clientZ4qxjB9WE5Meta?.path ?? "/client",
    meta: clientZ4qxjB9WE5Meta || {},
    alias: clientZ4qxjB9WE5Meta?.alias || [],
    redirect: clientZ4qxjB9WE5Meta?.redirect,
    component: () => import("/workspace/pages/client.vue").then(m => m.default || m),
    children: [
  {
    name: indexMi0KGM8ZPOMeta?.name ?? "client-billing",
    path: indexMi0KGM8ZPOMeta?.path ?? "billing",
    meta: indexMi0KGM8ZPOMeta || {},
    alias: indexMi0KGM8ZPOMeta?.alias || [],
    redirect: indexMi0KGM8ZPOMeta?.redirect,
    component: () => import("/workspace/pages/client/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexHZgmU4MuhRMeta?.name ?? "client-claims-claim_id",
    path: indexHZgmU4MuhRMeta?.path ?? "claims/:claim_id()",
    meta: indexHZgmU4MuhRMeta || {},
    alias: indexHZgmU4MuhRMeta?.alias || [],
    redirect: indexHZgmU4MuhRMeta?.redirect,
    component: () => import("/workspace/pages/client/claims/[claim_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyx0yD43MZ3Meta?.name ?? "client-claims",
    path: indexyx0yD43MZ3Meta?.path ?? "claims",
    meta: indexyx0yD43MZ3Meta || {},
    alias: indexyx0yD43MZ3Meta?.alias || [],
    redirect: indexyx0yD43MZ3Meta?.redirect,
    component: () => import("/workspace/pages/client/claims/index.vue").then(m => m.default || m)
  },
  {
    name: indexIT6ViVzxbiMeta?.name ?? "client",
    path: indexIT6ViVzxbiMeta?.path ?? "",
    meta: indexIT6ViVzxbiMeta || {},
    alias: indexIT6ViVzxbiMeta?.alias || [],
    redirect: indexIT6ViVzxbiMeta?.redirect,
    component: () => import("/workspace/pages/client/index.vue").then(m => m.default || m)
  },
  {
    name: indexknmlx7xwfBMeta?.name ?? "client-insurance",
    path: indexknmlx7xwfBMeta?.path ?? "insurance",
    meta: indexknmlx7xwfBMeta || {},
    alias: indexknmlx7xwfBMeta?.alias || [],
    redirect: indexknmlx7xwfBMeta?.redirect,
    component: () => import("/workspace/pages/client/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexkh5BRUooISMeta?.name ?? "client-providers",
    path: indexkh5BRUooISMeta?.path ?? "providers",
    meta: indexkh5BRUooISMeta || {},
    alias: indexkh5BRUooISMeta?.alias || [],
    redirect: indexkh5BRUooISMeta?.redirect,
    component: () => import("/workspace/pages/client/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexCWiFRsm0wGMeta?.name ?? "client-settings",
    path: indexCWiFRsm0wGMeta?.path ?? "settings",
    meta: indexCWiFRsm0wGMeta || {},
    alias: indexCWiFRsm0wGMeta?.alias || [],
    redirect: indexCWiFRsm0wGMeta?.redirect,
    component: () => import("/workspace/pages/client/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboardKIhr9Z9Xg1Meta?.name ?? undefined,
    path: dashboardKIhr9Z9Xg1Meta?.path ?? "/dashboard",
    meta: dashboardKIhr9Z9Xg1Meta || {},
    alias: dashboardKIhr9Z9Xg1Meta?.alias || [],
    redirect: dashboardKIhr9Z9Xg1Meta?.redirect,
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: accountsfvRKzPSAwkMeta?.name ?? undefined,
    path: accountsfvRKzPSAwkMeta?.path ?? "accounts",
    meta: accountsfvRKzPSAwkMeta || {},
    alias: accountsfvRKzPSAwkMeta?.alias || [],
    redirect: accountsfvRKzPSAwkMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93mgskJbq1fqMeta?.name ?? undefined,
    path: _91id_93mgskJbq1fqMeta?.path ?? ":id()",
    meta: _91id_93mgskJbq1fqMeta || {},
    alias: _91id_93mgskJbq1fqMeta?.alias || [],
    redirect: _91id_93mgskJbq1fqMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id].vue").then(m => m.default || m),
    children: [
  {
    name: addressesjzvlDehsZcMeta?.name ?? "dashboard-accounts-id-addresses",
    path: addressesjzvlDehsZcMeta?.path ?? "addresses",
    meta: addressesjzvlDehsZcMeta || {},
    alias: addressesjzvlDehsZcMeta?.alias || [],
    redirect: addressesjzvlDehsZcMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id]/addresses.vue").then(m => m.default || m)
  },
  {
    name: billingOBMDZv9MwtMeta?.name ?? "dashboard-accounts-id-billing",
    path: billingOBMDZv9MwtMeta?.path ?? "billing",
    meta: billingOBMDZv9MwtMeta || {},
    alias: billingOBMDZv9MwtMeta?.alias || [],
    redirect: billingOBMDZv9MwtMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id]/billing.vue").then(m => m.default || m)
  },
  {
    name: clientsWW9ehfJ57UMeta?.name ?? "dashboard-accounts-id-clients",
    path: clientsWW9ehfJ57UMeta?.path ?? "clients",
    meta: clientsWW9ehfJ57UMeta || {},
    alias: clientsWW9ehfJ57UMeta?.alias || [],
    redirect: clientsWW9ehfJ57UMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id]/clients.vue").then(m => m.default || m)
  },
  {
    name: indexyX0WFBVRpgMeta?.name ?? "dashboard-accounts-id",
    path: indexyX0WFBVRpgMeta?.path ?? "",
    meta: indexyX0WFBVRpgMeta || {},
    alias: indexyX0WFBVRpgMeta?.alias || [],
    redirect: indexyX0WFBVRpgMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: usersKLoI3HEeCpMeta?.name ?? "dashboard-accounts-id-users",
    path: usersKLoI3HEeCpMeta?.path ?? "users",
    meta: usersKLoI3HEeCpMeta || {},
    alias: usersKLoI3HEeCpMeta?.alias || [],
    redirect: usersKLoI3HEeCpMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/[id]/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexDISNErhJrUMeta?.name ?? "dashboard-accounts",
    path: indexDISNErhJrUMeta?.path ?? "",
    meta: indexDISNErhJrUMeta || {},
    alias: indexDISNErhJrUMeta?.alias || [],
    redirect: indexDISNErhJrUMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/accounts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVdJUDHNhFhMeta?.name ?? "dashboard-addresses",
    path: indexVdJUDHNhFhMeta?.path ?? "addresses",
    meta: indexVdJUDHNhFhMeta || {},
    alias: indexVdJUDHNhFhMeta?.alias || [],
    redirect: indexVdJUDHNhFhMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexh5QD4El8fgMeta?.name ?? "dashboard-auth-id",
    path: indexh5QD4El8fgMeta?.path ?? "auth/:id()",
    meta: indexh5QD4El8fgMeta || {},
    alias: indexh5QD4El8fgMeta?.alias || [],
    redirect: indexh5QD4El8fgMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/auth/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkDytVfeFAZMeta?.name ?? "dashboard-auth",
    path: indexkDytVfeFAZMeta?.path ?? "auth",
    meta: indexkDytVfeFAZMeta || {},
    alias: indexkDytVfeFAZMeta?.alias || [],
    redirect: indexkDytVfeFAZMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/auth/index.vue").then(m => m.default || m)
  },
  {
    name: claims8UzpXkD7zNMeta?.name ?? undefined,
    path: claims8UzpXkD7zNMeta?.path ?? "claims",
    meta: claims8UzpXkD7zNMeta || {},
    alias: claims8UzpXkD7zNMeta?.alias || [],
    redirect: claims8UzpXkD7zNMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93WWB9ZP9zmLMeta?.name ?? undefined,
    path: _91id_93WWB9ZP9zmLMeta?.path ?? ":id()",
    meta: _91id_93WWB9ZP9zmLMeta || {},
    alias: _91id_93WWB9ZP9zmLMeta?.alias || [],
    redirect: _91id_93WWB9ZP9zmLMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexNutcNQfsULMeta?.name ?? "dashboard-claims-id",
    path: indexNutcNQfsULMeta?.path ?? "",
    meta: indexNutcNQfsULMeta || {},
    alias: indexNutcNQfsULMeta?.alias || [],
    redirect: indexNutcNQfsULMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: invoiceEA71pV1pB0Meta?.name ?? "dashboard-claims-id-invoice",
    path: invoiceEA71pV1pB0Meta?.path ?? "invoice",
    meta: invoiceEA71pV1pB0Meta || {},
    alias: invoiceEA71pV1pB0Meta?.alias || [],
    redirect: invoiceEA71pV1pB0Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: paymentsBGsWTjCZZAMeta?.name ?? "dashboard-claims-id-payments",
    path: paymentsBGsWTjCZZAMeta?.path ?? "payments",
    meta: paymentsBGsWTjCZZAMeta || {},
    alias: paymentsBGsWTjCZZAMeta?.alias || [],
    redirect: paymentsBGsWTjCZZAMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: printMuMdhZfwdcMeta?.name ?? "dashboard-claims-id-print",
    path: printMuMdhZfwdcMeta?.path ?? "print",
    meta: printMuMdhZfwdcMeta || {},
    alias: printMuMdhZfwdcMeta?.alias || [],
    redirect: printMuMdhZfwdcMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/[id]/print.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexW59pxRUqmDMeta?.name ?? "dashboard-claims",
    path: indexW59pxRUqmDMeta?.path ?? "",
    meta: indexW59pxRUqmDMeta || {},
    alias: indexW59pxRUqmDMeta?.alias || [],
    redirect: indexW59pxRUqmDMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/claims/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: clientsEseddXbLLRMeta?.name ?? undefined,
    path: clientsEseddXbLLRMeta?.path ?? "clients",
    meta: clientsEseddXbLLRMeta || {},
    alias: clientsEseddXbLLRMeta?.alias || [],
    redirect: clientsEseddXbLLRMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients.vue").then(m => m.default || m),
    children: [
  {
    name: indexJFqmVtBmsuMeta?.name ?? "dashboard-clients-id",
    path: indexJFqmVtBmsuMeta?.path ?? ":id()",
    meta: indexJFqmVtBmsuMeta || {},
    alias: indexJFqmVtBmsuMeta?.alias || [],
    redirect: indexJFqmVtBmsuMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexROEunKH4ApMeta?.name ?? "dashboard-clients",
    path: indexROEunKH4ApMeta?.path ?? "",
    meta: indexROEunKH4ApMeta || {},
    alias: indexROEunKH4ApMeta?.alias || [],
    redirect: indexROEunKH4ApMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/clients/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2lrBtCOh90Meta?.name ?? "dashboard",
    path: index2lrBtCOh90Meta?.path ?? "",
    meta: index2lrBtCOh90Meta || {},
    alias: index2lrBtCOh90Meta?.alias || [],
    redirect: index2lrBtCOh90Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexRsEkqpOExzMeta?.name ?? "dashboard-insurance-payment-items",
    path: indexRsEkqpOExzMeta?.path ?? "insurance-payment-items",
    meta: indexRsEkqpOExzMeta || {},
    alias: indexRsEkqpOExzMeta?.alias || [],
    redirect: indexRsEkqpOExzMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance-payment-items/index.vue").then(m => m.default || m)
  },
  {
    name: insurance_45paymentsS4BrsUGHTPMeta?.name ?? undefined,
    path: insurance_45paymentsS4BrsUGHTPMeta?.path ?? "insurance-payments",
    meta: insurance_45paymentsS4BrsUGHTPMeta || {},
    alias: insurance_45paymentsS4BrsUGHTPMeta?.alias || [],
    redirect: insurance_45paymentsS4BrsUGHTPMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance-payments.vue").then(m => m.default || m),
    children: [
  {
    name: index74NXVCY2uXMeta?.name ?? "dashboard-insurance-payments-id",
    path: index74NXVCY2uXMeta?.path ?? ":id()",
    meta: index74NXVCY2uXMeta || {},
    alias: index74NXVCY2uXMeta?.alias || [],
    redirect: index74NXVCY2uXMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance-payments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextzRhIs7MbbMeta?.name ?? "dashboard-insurance-payments",
    path: indextzRhIs7MbbMeta?.path ?? "",
    meta: indextzRhIs7MbbMeta || {},
    alias: indextzRhIs7MbbMeta?.alias || [],
    redirect: indextzRhIs7MbbMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance-payments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexqzKJ9aTecXMeta?.name ?? "dashboard-insurance-id",
    path: indexqzKJ9aTecXMeta?.path ?? "insurance/:id()",
    meta: indexqzKJ9aTecXMeta || {},
    alias: indexqzKJ9aTecXMeta?.alias || [],
    redirect: indexqzKJ9aTecXMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexT3rqnGxdQZMeta?.name ?? "dashboard-insurance",
    path: indexT3rqnGxdQZMeta?.path ?? "insurance",
    meta: indexT3rqnGxdQZMeta || {},
    alias: indexT3rqnGxdQZMeta?.alias || [],
    redirect: indexT3rqnGxdQZMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: numo_45companiesFBPniJBaV1Meta?.name ?? undefined,
    path: numo_45companiesFBPniJBaV1Meta?.path ?? "numo-companies",
    meta: numo_45companiesFBPniJBaV1Meta || {},
    alias: numo_45companiesFBPniJBaV1Meta?.alias || [],
    redirect: numo_45companiesFBPniJBaV1Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/numo-companies.vue").then(m => m.default || m),
    children: [
  {
    name: indexG0QZfIOKfDMeta?.name ?? "dashboard-numo-companies-id",
    path: indexG0QZfIOKfDMeta?.path ?? ":id()",
    meta: indexG0QZfIOKfDMeta || {},
    alias: indexG0QZfIOKfDMeta?.alias || [],
    redirect: indexG0QZfIOKfDMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/numo-companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexL6ktIwT8TsMeta?.name ?? "dashboard-numo-companies",
    path: indexL6ktIwT8TsMeta?.path ?? "",
    meta: indexL6ktIwT8TsMeta || {},
    alias: indexL6ktIwT8TsMeta?.alias || [],
    redirect: indexL6ktIwT8TsMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/numo-companies/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexM7OX4NNg7mMeta?.name ?? "dashboard-numo-invoices",
    path: indexM7OX4NNg7mMeta?.path ?? "numo-invoices",
    meta: indexM7OX4NNg7mMeta || {},
    alias: indexM7OX4NNg7mMeta?.alias || [],
    redirect: indexM7OX4NNg7mMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/numo-invoices/index.vue").then(m => m.default || m)
  },
  {
    name: partnersp4cpYQ5Pm1Meta?.name ?? undefined,
    path: partnersp4cpYQ5Pm1Meta?.path ?? "partners",
    meta: partnersp4cpYQ5Pm1Meta || {},
    alias: partnersp4cpYQ5Pm1Meta?.alias || [],
    redirect: partnersp4cpYQ5Pm1Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9391icsgpYOSMeta?.name ?? undefined,
    path: _91id_9391icsgpYOSMeta?.path ?? ":id()",
    meta: _91id_9391icsgpYOSMeta || {},
    alias: _91id_9391icsgpYOSMeta?.alias || [],
    redirect: _91id_9391icsgpYOSMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexwgFd6KWhOvMeta?.name ?? "dashboard-partners-id",
    path: indexwgFd6KWhOvMeta?.path ?? "",
    meta: indexwgFd6KWhOvMeta || {},
    alias: indexwgFd6KWhOvMeta?.alias || [],
    redirect: indexwgFd6KWhOvMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: payoutsuL4oJp7YCRMeta?.name ?? "dashboard-partners-id-payouts",
    path: payoutsuL4oJp7YCRMeta?.path ?? "payouts",
    meta: payoutsuL4oJp7YCRMeta || {},
    alias: payoutsuL4oJp7YCRMeta?.alias || [],
    redirect: payoutsuL4oJp7YCRMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/[id]/payouts.vue").then(m => m.default || m)
  },
  {
    name: productshTlTOWNgB5Meta?.name ?? "dashboard-partners-id-products",
    path: productshTlTOWNgB5Meta?.path ?? "products",
    meta: productshTlTOWNgB5Meta || {},
    alias: productshTlTOWNgB5Meta?.alias || [],
    redirect: productshTlTOWNgB5Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/[id]/products.vue").then(m => m.default || m)
  },
  {
    name: providers7PV3gxkDZqMeta?.name ?? "dashboard-partners-id-providers",
    path: providers7PV3gxkDZqMeta?.path ?? "providers",
    meta: providers7PV3gxkDZqMeta || {},
    alias: providers7PV3gxkDZqMeta?.alias || [],
    redirect: providers7PV3gxkDZqMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/[id]/providers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFtMwrN63UgMeta?.name ?? "dashboard-partners",
    path: indexFtMwrN63UgMeta?.path ?? "",
    meta: indexFtMwrN63UgMeta || {},
    alias: indexFtMwrN63UgMeta?.alias || [],
    redirect: indexFtMwrN63UgMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexI4g2FBmKekMeta?.name ?? "dashboard-payment-items",
    path: indexI4g2FBmKekMeta?.path ?? "payment-items",
    meta: indexI4g2FBmKekMeta || {},
    alias: indexI4g2FBmKekMeta?.alias || [],
    redirect: indexI4g2FBmKekMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payment-items/index.vue").then(m => m.default || m)
  },
  {
    name: index7QiRiy80KdMeta?.name ?? "dashboard-payments-client-id",
    path: index7QiRiy80KdMeta?.path ?? "payments-client/:id()",
    meta: index7QiRiy80KdMeta || {},
    alias: index7QiRiy80KdMeta?.alias || [],
    redirect: index7QiRiy80KdMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payments-client/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5Hg7ehFH9Meta?.name ?? "dashboard-payments-client",
    path: indexy5Hg7ehFH9Meta?.path ?? "payments-client",
    meta: indexy5Hg7ehFH9Meta || {},
    alias: indexy5Hg7ehFH9Meta?.alias || [],
    redirect: indexy5Hg7ehFH9Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/payments-client/index.vue").then(m => m.default || m)
  },
  {
    name: paymentsyh4b7pknHLMeta?.name ?? undefined,
    path: paymentsyh4b7pknHLMeta?.path ?? "payments",
    meta: paymentsyh4b7pknHLMeta || {},
    alias: paymentsyh4b7pknHLMeta?.alias || [],
    redirect: paymentsyh4b7pknHLMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payments.vue").then(m => m.default || m),
    children: [
  {
    name: indexxruUTUAtEvMeta?.name ?? "dashboard-payments-id",
    path: indexxruUTUAtEvMeta?.path ?? ":id()",
    meta: indexxruUTUAtEvMeta || {},
    alias: indexxruUTUAtEvMeta?.alias || [],
    redirect: indexxruUTUAtEvMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexd95mMhV6DZMeta?.name ?? "dashboard-payments",
    path: indexd95mMhV6DZMeta?.path ?? "",
    meta: indexd95mMhV6DZMeta || {},
    alias: indexd95mMhV6DZMeta?.alias || [],
    redirect: indexd95mMhV6DZMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payments/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexQXnq7Gcy9OMeta?.name ?? "dashboard-payout-items",
    path: indexQXnq7Gcy9OMeta?.path ?? "payout-items",
    meta: indexQXnq7Gcy9OMeta || {},
    alias: indexQXnq7Gcy9OMeta?.alias || [],
    redirect: indexQXnq7Gcy9OMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payout-items/index.vue").then(m => m.default || m)
  },
  {
    name: payouts3nVadYxOwoMeta?.name ?? undefined,
    path: payouts3nVadYxOwoMeta?.path ?? "payouts",
    meta: payouts3nVadYxOwoMeta || {},
    alias: payouts3nVadYxOwoMeta?.alias || [],
    redirect: payouts3nVadYxOwoMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: index8ITPabIyrCMeta?.name ?? "dashboard-payouts-id",
    path: index8ITPabIyrCMeta?.path ?? ":id()",
    meta: index8ITPabIyrCMeta || {},
    alias: index8ITPabIyrCMeta?.alias || [],
    redirect: index8ITPabIyrCMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNhBYRpF8XMeta?.name ?? "dashboard-payouts",
    path: indexpNhBYRpF8XMeta?.path ?? "",
    meta: indexpNhBYRpF8XMeta || {},
    alias: indexpNhBYRpF8XMeta?.alias || [],
    redirect: indexpNhBYRpF8XMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexjA4rcI035oMeta?.name ?? "dashboard-policy",
    path: indexjA4rcI035oMeta?.path ?? "policy",
    meta: indexjA4rcI035oMeta || {},
    alias: indexjA4rcI035oMeta?.alias || [],
    redirect: indexjA4rcI035oMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexlVGRvEkYBOMeta?.name ?? "dashboard-providers",
    path: indexlVGRvEkYBOMeta?.path ?? "providers",
    meta: indexlVGRvEkYBOMeta || {},
    alias: indexlVGRvEkYBOMeta?.alias || [],
    redirect: indexlVGRvEkYBOMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAQZJMeuohMeta?.name ?? "dashboard-users-fb-id",
    path: indexqAQZJMeuohMeta?.path ?? "users-fb/:id()",
    meta: indexqAQZJMeuohMeta || {},
    alias: indexqAQZJMeuohMeta?.alias || [],
    redirect: indexqAQZJMeuohMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/users-fb/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXjank1jTf8Meta?.name ?? "dashboard-users-fb",
    path: indexXjank1jTf8Meta?.path ?? "users-fb",
    meta: indexXjank1jTf8Meta || {},
    alias: indexXjank1jTf8Meta?.alias || [],
    redirect: indexXjank1jTf8Meta?.redirect,
    component: () => import("/workspace/pages/dashboard/users-fb/index.vue").then(m => m.default || m)
  },
  {
    name: indexa7zSI429KXMeta?.name ?? "dashboard-users-id",
    path: indexa7zSI429KXMeta?.path ?? "users/:id()",
    meta: indexa7zSI429KXMeta || {},
    alias: indexa7zSI429KXMeta?.alias || [],
    redirect: indexa7zSI429KXMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY6hBUUdN4MMeta?.name ?? "dashboard-users",
    path: indexY6hBUUdN4MMeta?.path ?? "users",
    meta: indexY6hBUUdN4MMeta || {},
    alias: indexY6hBUUdN4MMeta?.alias || [],
    redirect: indexY6hBUUdN4MMeta?.redirect,
    component: () => import("/workspace/pages/dashboard/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91city_id_932jux7r4bRsMeta?.name ?? "find-care-city_id",
    path: _91city_id_932jux7r4bRsMeta?.path ?? "/find-care/:city_id()",
    meta: _91city_id_932jux7r4bRsMeta || {},
    alias: _91city_id_932jux7r4bRsMeta?.alias || [],
    redirect: _91city_id_932jux7r4bRsMeta?.redirect,
    component: () => import("/workspace/pages/find-care/[city_id].vue").then(m => m.default || m)
  },
  {
    name: indexMHaTGPSBJdMeta?.name ?? "find-country-city-insurance",
    path: indexMHaTGPSBJdMeta?.path ?? "/find/:country()/:city()/:insurance()",
    meta: indexMHaTGPSBJdMeta || {},
    alias: indexMHaTGPSBJdMeta?.alias || [],
    redirect: indexMHaTGPSBJdMeta?.redirect,
    component: () => import("/workspace/pages/find/[country]/[city]/[insurance]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUEw2kz0IXrMeta?.name ?? "find-country-city",
    path: indexUEw2kz0IXrMeta?.path ?? "/find/:country()/:city()",
    meta: indexUEw2kz0IXrMeta || {},
    alias: indexUEw2kz0IXrMeta?.alias || [],
    redirect: indexUEw2kz0IXrMeta?.redirect,
    component: () => import("/workspace/pages/find/[country]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXjHepd63zcMeta?.name ?? "find-country",
    path: indexXjHepd63zcMeta?.path ?? "/find/:country()",
    meta: indexXjHepd63zcMeta || {},
    alias: indexXjHepd63zcMeta?.alias || [],
    redirect: indexXjHepd63zcMeta?.redirect,
    component: () => import("/workspace/pages/find/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpQ8UTyU8aaMeta?.name ?? "find",
    path: indexpQ8UTyU8aaMeta?.path ?? "/find",
    meta: indexpQ8UTyU8aaMeta || {},
    alias: indexpQ8UTyU8aaMeta?.alias || [],
    redirect: indexpQ8UTyU8aaMeta?.redirect,
    component: () => import("/workspace/pages/find/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtUpaPOwALMeta?.name ?? "for-providers",
    path: indexxtUpaPOwALMeta?.path ?? "/for-providers",
    meta: indexxtUpaPOwALMeta || {},
    alias: indexxtUpaPOwALMeta?.alias || [],
    redirect: indexxtUpaPOwALMeta?.redirect,
    component: () => import("/workspace/pages/for-providers/index.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdEfuMmlaquMeta?.name ?? "login-verify",
    path: indexdEfuMmlaquMeta?.path ?? "/login-verify",
    meta: indexdEfuMmlaquMeta || {},
    alias: indexdEfuMmlaquMeta?.alias || [],
    redirect: indexdEfuMmlaquMeta?.redirect,
    component: () => import("/workspace/pages/login-verify/index.vue").then(m => m.default || m)
  },
  {
    name: indextTkPG1jUYQMeta?.name ?? "login",
    path: indextTkPG1jUYQMeta?.path ?? "/login",
    meta: indextTkPG1jUYQMeta || {},
    alias: indextTkPG1jUYQMeta?.alias || [],
    redirect: indextTkPG1jUYQMeta?.redirect,
    component: () => import("/workspace/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: onboard8CtHldbEa1Meta?.name ?? undefined,
    path: onboard8CtHldbEa1Meta?.path ?? "/onboard",
    meta: onboard8CtHldbEa1Meta || {},
    alias: onboard8CtHldbEa1Meta?.alias || [],
    redirect: onboard8CtHldbEa1Meta?.redirect,
    component: () => import("/workspace/pages/onboard.vue").then(m => m.default || m),
    children: [
  {
    name: _91partner_slug_93wEKnrLzytPMeta?.name ?? "onboard-partner_slug",
    path: _91partner_slug_93wEKnrLzytPMeta?.path ?? ":partner_slug()",
    meta: _91partner_slug_93wEKnrLzytPMeta || {},
    alias: _91partner_slug_93wEKnrLzytPMeta?.alias || [],
    redirect: _91partner_slug_93wEKnrLzytPMeta?.redirect,
    component: () => import("/workspace/pages/onboard/[partner_slug].vue").then(m => m.default || m)
  },
  {
    name: indexqaRTieiQTAMeta?.name ?? "onboard-billing",
    path: indexqaRTieiQTAMeta?.path ?? "billing",
    meta: indexqaRTieiQTAMeta || {},
    alias: indexqaRTieiQTAMeta?.alias || [],
    redirect: indexqaRTieiQTAMeta?.redirect,
    component: () => import("/workspace/pages/onboard/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexep2FVeuO1hMeta?.name ?? "onboard-card-upload",
    path: indexep2FVeuO1hMeta?.path ?? "card-upload",
    meta: indexep2FVeuO1hMeta || {},
    alias: indexep2FVeuO1hMeta?.alias || [],
    redirect: indexep2FVeuO1hMeta?.redirect,
    component: () => import("/workspace/pages/onboard/card-upload/index.vue").then(m => m.default || m)
  },
  {
    name: index7pvcQ4WbaeMeta?.name ?? "onboard-clients",
    path: index7pvcQ4WbaeMeta?.path ?? "clients",
    meta: index7pvcQ4WbaeMeta || {},
    alias: index7pvcQ4WbaeMeta?.alias || [],
    redirect: index7pvcQ4WbaeMeta?.redirect,
    component: () => import("/workspace/pages/onboard/clients/index.vue").then(m => m.default || m)
  },
  {
    name: indexekDEk6fbD7Meta?.name ?? "onboard-eligibility",
    path: indexekDEk6fbD7Meta?.path ?? "eligibility",
    meta: indexekDEk6fbD7Meta || {},
    alias: indexekDEk6fbD7Meta?.alias || [],
    redirect: indexekDEk6fbD7Meta?.redirect,
    component: () => import("/workspace/pages/onboard/eligibility/index.vue").then(m => m.default || m)
  },
  {
    name: indexzAVvhj0a33Meta?.name ?? "onboard",
    path: indexzAVvhj0a33Meta?.path ?? "",
    meta: indexzAVvhj0a33Meta || {},
    alias: indexzAVvhj0a33Meta?.alias || [],
    redirect: indexzAVvhj0a33Meta?.redirect,
    component: () => import("/workspace/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexk4RQNAn1A2Meta?.name ?? "onboard-insurance",
    path: indexk4RQNAn1A2Meta?.path ?? "insurance",
    meta: indexk4RQNAn1A2Meta || {},
    alias: indexk4RQNAn1A2Meta?.alias || [],
    redirect: indexk4RQNAn1A2Meta?.redirect,
    component: () => import("/workspace/pages/onboard/insurance/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91partner_slug_93PudgRMACp8Meta?.name ?? "onboard2-partner_slug",
    path: _91partner_slug_93PudgRMACp8Meta?.path ?? "/onboard2/:partner_slug()",
    meta: _91partner_slug_93PudgRMACp8Meta || {},
    alias: _91partner_slug_93PudgRMACp8Meta?.alias || [],
    redirect: _91partner_slug_93PudgRMACp8Meta?.redirect,
    component: () => import("/workspace/pages/onboard2/[partner_slug].vue").then(m => m.default || m)
  },
  {
    name: indexdvwnXYrSqIMeta?.name ?? "onboard2",
    path: indexdvwnXYrSqIMeta?.path ?? "/onboard2",
    meta: indexdvwnXYrSqIMeta || {},
    alias: indexdvwnXYrSqIMeta?.alias || [],
    redirect: indexdvwnXYrSqIMeta?.redirect,
    component: () => import("/workspace/pages/onboard2/index.vue").then(m => m.default || m)
  },
  {
    name: afspaBpOzMkNnYMMeta?.name ?? "print-claim-claim_id-invoice-afspa",
    path: afspaBpOzMkNnYMMeta?.path ?? "/print/claim/:claim_id()/invoice/afspa",
    meta: afspaBpOzMkNnYMMeta || {},
    alias: afspaBpOzMkNnYMMeta?.alias || [],
    redirect: afspaBpOzMkNnYMMeta?.redirect,
    component: () => import("/workspace/pages/print/claim/[claim_id]/invoice/afspa.vue").then(m => m.default || m)
  },
  {
    name: indexVhHsHl5loxMeta?.name ?? "print-claim-claim_id-invoice",
    path: indexVhHsHl5loxMeta?.path ?? "/print/claim/:claim_id()/invoice",
    meta: indexVhHsHl5loxMeta || {},
    alias: indexVhHsHl5loxMeta?.alias || [],
    redirect: indexVhHsHl5loxMeta?.redirect,
    component: () => import("/workspace/pages/print/claim/[claim_id]/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: va_45fmp45JDc1wnikMeta?.name ?? "print-claim-claim_id-invoice-va-fmp",
    path: va_45fmp45JDc1wnikMeta?.path ?? "/print/claim/:claim_id()/invoice/va-fmp",
    meta: va_45fmp45JDc1wnikMeta || {},
    alias: va_45fmp45JDc1wnikMeta?.alias || [],
    redirect: va_45fmp45JDc1wnikMeta?.redirect,
    component: () => import("/workspace/pages/print/claim/[claim_id]/invoice/va-fmp.vue").then(m => m.default || m)
  },
  {
    name: bank_45info0vJ72NeQEuMeta?.name ?? "print-partner-partner_id-bank-info",
    path: bank_45info0vJ72NeQEuMeta?.path ?? "/print/partner/:partner_id()/bank-info",
    meta: bank_45info0vJ72NeQEuMeta || {},
    alias: bank_45info0vJ72NeQEuMeta?.alias || [],
    redirect: bank_45info0vJ72NeQEuMeta?.redirect,
    component: () => import("/workspace/pages/print/partner/[partner_id]/bank-info.vue").then(m => m.default || m)
  },
  {
    name: index21M5iF70M6Meta?.name ?? "privacy-policy",
    path: index21M5iF70M6Meta?.path ?? "/privacy-policy",
    meta: index21M5iF70M6Meta || {},
    alias: index21M5iF70M6Meta?.alias || [],
    redirect: index21M5iF70M6Meta?.redirect,
    component: () => import("/workspace/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: providerxTIlQH7m3VMeta?.name ?? undefined,
    path: providerxTIlQH7m3VMeta?.path ?? "/provider",
    meta: providerxTIlQH7m3VMeta || {},
    alias: providerxTIlQH7m3VMeta?.alias || [],
    redirect: providerxTIlQH7m3VMeta?.redirect,
    component: () => import("/workspace/pages/provider.vue").then(m => m.default || m),
    children: [
  {
    name: indexgWykPGTeWyMeta?.name ?? "provider--claims_old-claim_id",
    path: indexgWykPGTeWyMeta?.path ?? "-claims_old/:claim_id()",
    meta: indexgWykPGTeWyMeta || {},
    alias: indexgWykPGTeWyMeta?.alias || [],
    redirect: indexgWykPGTeWyMeta?.redirect,
    component: () => import("/workspace/pages/provider/-claims_old/[claim_id]/index.vue").then(m => m.default || m)
  },
  {
    name: invoiceOtAApTA8x1Meta?.name ?? "provider--claims_old-claim_id-invoice",
    path: invoiceOtAApTA8x1Meta?.path ?? "-claims_old/:claim_id()/invoice",
    meta: invoiceOtAApTA8x1Meta || {},
    alias: invoiceOtAApTA8x1Meta?.alias || [],
    redirect: invoiceOtAApTA8x1Meta?.redirect,
    component: () => import("/workspace/pages/provider/-claims_old/[claim_id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: indexP2cYlQUY2jMeta?.name ?? "provider--claims_old",
    path: indexP2cYlQUY2jMeta?.path ?? "-claims_old",
    meta: indexP2cYlQUY2jMeta || {},
    alias: indexP2cYlQUY2jMeta?.alias || [],
    redirect: indexP2cYlQUY2jMeta?.redirect,
    component: () => import("/workspace/pages/provider/-claims_old/index.vue").then(m => m.default || m)
  },
  {
    name: _91selection_93GnBvNMW40CMeta?.name ?? "provider--payouts_old-list-selection",
    path: _91selection_93GnBvNMW40CMeta?.path ?? "-payouts_old/list/:selection()",
    meta: _91selection_93GnBvNMW40CMeta || {},
    alias: _91selection_93GnBvNMW40CMeta?.alias || [],
    redirect: _91selection_93GnBvNMW40CMeta?.redirect,
    component: () => import("/workspace/pages/provider/-payouts_old/list/[selection].vue").then(m => m.default || m)
  },
  {
    name: indextU0vjecuRKMeta?.name ?? "provider--payouts_old-payout-payout_id",
    path: indextU0vjecuRKMeta?.path ?? "-payouts_old/payout/:payout_id()",
    meta: indextU0vjecuRKMeta || {},
    alias: indextU0vjecuRKMeta?.alias || [],
    redirect: indextU0vjecuRKMeta?.redirect,
    component: () => import("/workspace/pages/provider/-payouts_old/payout/[payout_id]/index.vue").then(m => m.default || m)
  },
  {
    name: editBNCCmJAIUbMeta?.name ?? "provider-claims-claim_id-edit",
    path: editBNCCmJAIUbMeta?.path ?? "claims/:claim_id()/edit",
    meta: editBNCCmJAIUbMeta || {},
    alias: editBNCCmJAIUbMeta?.alias || [],
    redirect: editBNCCmJAIUbMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/[claim_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMA9sINlfhBMeta?.name ?? "provider-claims-claim_id",
    path: indexMA9sINlfhBMeta?.path ?? "claims/:claim_id()",
    meta: indexMA9sINlfhBMeta || {},
    alias: indexMA9sINlfhBMeta?.alias || [],
    redirect: indexMA9sINlfhBMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/[claim_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91claim_id_9361TzopFA0DMeta?.name ?? "provider-claims-claim-claim_id",
    path: _91claim_id_9361TzopFA0DMeta?.path ?? "claims/claim/:claim_id()",
    meta: _91claim_id_9361TzopFA0DMeta || {},
    alias: _91claim_id_9361TzopFA0DMeta?.alias || [],
    redirect: _91claim_id_9361TzopFA0DMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/claim/[claim_id].vue").then(m => m.default || m)
  },
  {
    name: indexUrPiHdwzypMeta?.name ?? "provider-claims",
    path: indexUrPiHdwzypMeta?.path ?? "claims",
    meta: indexUrPiHdwzypMeta || {},
    alias: indexUrPiHdwzypMeta?.alias || [],
    redirect: indexUrPiHdwzypMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/index.vue").then(m => m.default || m)
  },
  {
    name: _91selection_93C93NS6PZwjMeta?.name ?? "provider-claims-list-selection",
    path: _91selection_93C93NS6PZwjMeta?.path ?? "claims/list/:selection()",
    meta: _91selection_93C93NS6PZwjMeta || {},
    alias: _91selection_93C93NS6PZwjMeta?.alias || [],
    redirect: _91selection_93C93NS6PZwjMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/list/[selection].vue").then(m => m.default || m)
  },
  {
    name: newcF451ih8nwMeta?.name ?? "provider-claims-new",
    path: newcF451ih8nwMeta?.path ?? "claims/new",
    meta: newcF451ih8nwMeta || {},
    alias: newcF451ih8nwMeta?.alias || [],
    redirect: newcF451ih8nwMeta?.redirect,
    component: () => import("/workspace/pages/provider/claims/new.vue").then(m => m.default || m)
  },
  {
    name: _91client_id_93kxrFej6VohMeta?.name ?? "provider-clients-list-client_id",
    path: _91client_id_93kxrFej6VohMeta?.path ?? "clients-list/:client_id()",
    meta: _91client_id_93kxrFej6VohMeta || {},
    alias: _91client_id_93kxrFej6VohMeta?.alias || [],
    redirect: _91client_id_93kxrFej6VohMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients-list/[client_id].vue").then(m => m.default || m)
  },
  {
    name: indexqRNyRdrsgjMeta?.name ?? "provider-clients-list",
    path: indexqRNyRdrsgjMeta?.path ?? "clients-list",
    meta: indexqRNyRdrsgjMeta || {},
    alias: indexqRNyRdrsgjMeta?.alias || [],
    redirect: indexqRNyRdrsgjMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients-list/index.vue").then(m => m.default || m)
  },
  {
    name: indexVqAiCRL7juMeta?.name ?? "provider-clients-client_id",
    path: indexVqAiCRL7juMeta?.path ?? "clients/:client_id()",
    meta: indexVqAiCRL7juMeta || {},
    alias: indexVqAiCRL7juMeta?.alias || [],
    redirect: indexVqAiCRL7juMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/[client_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91client_id_933ZDI9uIh2KMeta?.name ?? undefined,
    path: _91client_id_933ZDI9uIh2KMeta?.path ?? "clients/client/:client_id()",
    meta: _91client_id_933ZDI9uIh2KMeta || {},
    alias: _91client_id_933ZDI9uIh2KMeta?.alias || [],
    redirect: _91client_id_933ZDI9uIh2KMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id].vue").then(m => m.default || m),
    children: [
  {
    name: _91claim_id_93oNnc4cUAZoMeta?.name ?? "provider-clients-client-client_id-claim-claim_id",
    path: _91claim_id_93oNnc4cUAZoMeta?.path ?? "claim/:claim_id()",
    meta: _91claim_id_93oNnc4cUAZoMeta || {},
    alias: _91claim_id_93oNnc4cUAZoMeta?.alias || [],
    redirect: _91claim_id_93oNnc4cUAZoMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/claim/[claim_id].vue").then(m => m.default || m)
  },
  {
    name: indexi2TqUycvaZMeta?.name ?? "provider-clients-client-client_id-claim",
    path: indexi2TqUycvaZMeta?.path ?? "claim",
    meta: indexi2TqUycvaZMeta || {},
    alias: indexi2TqUycvaZMeta?.alias || [],
    redirect: indexi2TqUycvaZMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/claim/index.vue").then(m => m.default || m)
  },
  {
    name: claims79FqQlL2KEMeta?.name ?? "provider-clients-client-client_id-claims",
    path: claims79FqQlL2KEMeta?.path ?? "claims",
    meta: claims79FqQlL2KEMeta || {},
    alias: claims79FqQlL2KEMeta?.alias || [],
    redirect: claims79FqQlL2KEMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/claims.vue").then(m => m.default || m)
  },
  {
    name: documentsxdUtdYck8DMeta?.name ?? "provider-clients-client-client_id-documents",
    path: documentsxdUtdYck8DMeta?.path ?? "documents",
    meta: documentsxdUtdYck8DMeta || {},
    alias: documentsxdUtdYck8DMeta?.alias || [],
    redirect: documentsxdUtdYck8DMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/documents.vue").then(m => m.default || m)
  },
  {
    name: indexUI5cz6jZkgMeta?.name ?? "provider-clients-client-client_id",
    path: indexUI5cz6jZkgMeta?.path ?? "",
    meta: indexUI5cz6jZkgMeta || {},
    alias: indexUI5cz6jZkgMeta?.alias || [],
    redirect: indexUI5cz6jZkgMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/index.vue").then(m => m.default || m)
  },
  {
    name: profileovvEC0WU7lMeta?.name ?? "provider-clients-client-client_id-profile",
    path: profileovvEC0WU7lMeta?.path ?? "profile",
    meta: profileovvEC0WU7lMeta || {},
    alias: profileovvEC0WU7lMeta?.alias || [],
    redirect: profileovvEC0WU7lMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/profile.vue").then(m => m.default || m)
  },
  {
    name: servicesCkH5zlhIQ9Meta?.name ?? "provider-clients-client-client_id-services",
    path: servicesCkH5zlhIQ9Meta?.path ?? "services",
    meta: servicesCkH5zlhIQ9Meta || {},
    alias: servicesCkH5zlhIQ9Meta?.alias || [],
    redirect: servicesCkH5zlhIQ9Meta?.redirect,
    component: () => import("/workspace/pages/provider/clients/client/[client_id]/services.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91selection_93CkmU6HW7WxMeta?.name ?? "provider-clients-list-selection",
    path: _91selection_93CkmU6HW7WxMeta?.path ?? "clients/list/:selection()",
    meta: _91selection_93CkmU6HW7WxMeta || {},
    alias: _91selection_93CkmU6HW7WxMeta?.alias || [],
    redirect: _91selection_93CkmU6HW7WxMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/list/[selection].vue").then(m => m.default || m)
  },
  {
    name: indexQFvE4umZKsMeta?.name ?? "provider-clients-onboard",
    path: indexQFvE4umZKsMeta?.path ?? "clients/onboard",
    meta: indexQFvE4umZKsMeta || {},
    alias: indexQFvE4umZKsMeta?.alias || [],
    redirect: indexQFvE4umZKsMeta?.redirect,
    component: () => import("/workspace/pages/provider/clients/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexs7ql82qALeMeta?.name ?? "provider-dashboard",
    path: indexs7ql82qALeMeta?.path ?? "dashboard",
    meta: indexs7ql82qALeMeta || {},
    alias: indexs7ql82qALeMeta?.alias || [],
    redirect: indexs7ql82qALeMeta?.redirect,
    component: () => import("/workspace/pages/provider/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexttU3t6KIDyMeta?.name ?? "provider",
    path: indexttU3t6KIDyMeta?.path ?? "",
    meta: indexttU3t6KIDyMeta || {},
    alias: indexttU3t6KIDyMeta?.alias || [],
    redirect: indexttU3t6KIDyMeta?.redirect,
    component: () => import("/workspace/pages/provider/index.vue").then(m => m.default || m)
  },
  {
    name: _91insurance_id_931KqgejHqNsMeta?.name ?? "provider-insurance-insurance_id",
    path: _91insurance_id_931KqgejHqNsMeta?.path ?? "insurance/:insurance_id()",
    meta: _91insurance_id_931KqgejHqNsMeta || {},
    alias: _91insurance_id_931KqgejHqNsMeta?.alias || [],
    redirect: _91insurance_id_931KqgejHqNsMeta?.redirect,
    component: () => import("/workspace/pages/provider/insurance/[insurance_id].vue").then(m => m.default || m)
  },
  {
    name: _91invoice_id_93nzh0zNKC02Meta?.name ?? "provider-invoices-invoice_id",
    path: _91invoice_id_93nzh0zNKC02Meta?.path ?? "invoices/:invoice_id()",
    meta: _91invoice_id_93nzh0zNKC02Meta || {},
    alias: _91invoice_id_93nzh0zNKC02Meta?.alias || [],
    redirect: _91invoice_id_93nzh0zNKC02Meta?.redirect,
    component: () => import("/workspace/pages/provider/invoices/[invoice_id].vue").then(m => m.default || m)
  },
  {
    name: indexWeSrR0wGrZMeta?.name ?? "provider-invoices",
    path: indexWeSrR0wGrZMeta?.path ?? "invoices",
    meta: indexWeSrR0wGrZMeta || {},
    alias: indexWeSrR0wGrZMeta?.alias || [],
    redirect: indexWeSrR0wGrZMeta?.redirect,
    component: () => import("/workspace/pages/provider/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexl3Xq7qfXj9Meta?.name ?? "provider-notifications",
    path: indexl3Xq7qfXj9Meta?.path ?? "notifications",
    meta: indexl3Xq7qfXj9Meta || {},
    alias: indexl3Xq7qfXj9Meta?.alias || [],
    redirect: indexl3Xq7qfXj9Meta?.redirect,
    component: () => import("/workspace/pages/provider/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: _91selection_937FmODMqbQcMeta?.name ?? "provider-payments-list-selection",
    path: _91selection_937FmODMqbQcMeta?.path ?? "payments/list/:selection()",
    meta: _91selection_937FmODMqbQcMeta || {},
    alias: _91selection_937FmODMqbQcMeta?.alias || [],
    redirect: _91selection_937FmODMqbQcMeta?.redirect,
    component: () => import("/workspace/pages/provider/payments/list/[selection].vue").then(m => m.default || m)
  },
  {
    name: index41NMszAI79Meta?.name ?? "provider-payments-payment-payment_id",
    path: index41NMszAI79Meta?.path ?? "payments/payment/:payment_id()",
    meta: index41NMszAI79Meta || {},
    alias: index41NMszAI79Meta?.alias || [],
    redirect: index41NMszAI79Meta?.redirect,
    component: () => import("/workspace/pages/provider/payments/payment/[payment_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmiljkZmdxMMeta?.name ?? "provider-payouts-payout_id",
    path: indexmiljkZmdxMMeta?.path ?? "payouts/:payout_id()",
    meta: indexmiljkZmdxMMeta || {},
    alias: indexmiljkZmdxMMeta?.alias || [],
    redirect: indexmiljkZmdxMMeta?.redirect,
    component: () => import("/workspace/pages/provider/payouts/[payout_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1n0P0gKs28Meta?.name ?? "provider-payouts",
    path: index1n0P0gKs28Meta?.path ?? "payouts",
    meta: index1n0P0gKs28Meta || {},
    alias: index1n0P0gKs28Meta?.alias || [],
    redirect: index1n0P0gKs28Meta?.redirect,
    component: () => import("/workspace/pages/provider/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexetRYwnOkRkMeta?.name ?? "provider-profile",
    path: indexetRYwnOkRkMeta?.path ?? "profile",
    meta: indexetRYwnOkRkMeta || {},
    alias: indexetRYwnOkRkMeta?.alias || [],
    redirect: indexetRYwnOkRkMeta?.redirect,
    component: () => import("/workspace/pages/provider/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91provider_id_93RcYw175uK0Meta?.name ?? "provider-providers-provider_id",
    path: _91provider_id_93RcYw175uK0Meta?.path ?? "providers/:provider_id()",
    meta: _91provider_id_93RcYw175uK0Meta || {},
    alias: _91provider_id_93RcYw175uK0Meta?.alias || [],
    redirect: _91provider_id_93RcYw175uK0Meta?.redirect,
    component: () => import("/workspace/pages/provider/providers/[provider_id].vue").then(m => m.default || m)
  },
  {
    name: indexceVaDNURJvMeta?.name ?? "provider-providers",
    path: indexceVaDNURJvMeta?.path ?? "providers",
    meta: indexceVaDNURJvMeta || {},
    alias: indexceVaDNURJvMeta?.alias || [],
    redirect: indexceVaDNURJvMeta?.redirect,
    component: () => import("/workspace/pages/provider/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKrqpFAHfXfMeta?.name ?? "provider-reports",
    path: indexKrqpFAHfXfMeta?.path ?? "reports",
    meta: indexKrqpFAHfXfMeta || {},
    alias: indexKrqpFAHfXfMeta?.alias || [],
    redirect: indexKrqpFAHfXfMeta?.redirect,
    component: () => import("/workspace/pages/provider/reports/index.vue").then(m => m.default || m)
  },
  {
    name: index9BPUkSiXt5Meta?.name ?? "provider-services",
    path: index9BPUkSiXt5Meta?.path ?? "services",
    meta: index9BPUkSiXt5Meta || {},
    alias: index9BPUkSiXt5Meta?.alias || [],
    redirect: index9BPUkSiXt5Meta?.redirect,
    component: () => import("/workspace/pages/provider/services/index.vue").then(m => m.default || m)
  },
  {
    name: indexfpHyeVEyBOMeta?.name ?? "provider-settings",
    path: indexfpHyeVEyBOMeta?.path ?? "settings",
    meta: indexfpHyeVEyBOMeta || {},
    alias: indexfpHyeVEyBOMeta?.alias || [],
    redirect: indexfpHyeVEyBOMeta?.redirect,
    component: () => import("/workspace/pages/provider/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexBvS6rp05y4Meta?.name ?? "provider-user",
    path: indexBvS6rp05y4Meta?.path ?? "user",
    meta: indexBvS6rp05y4Meta || {},
    alias: indexBvS6rp05y4Meta?.alias || [],
    redirect: indexBvS6rp05y4Meta?.redirect,
    component: () => import("/workspace/pages/provider/user/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexcPdLjFv6ymMeta?.name ?? "providers-slug",
    path: indexcPdLjFv6ymMeta?.path ?? "/providers/:slug()",
    meta: indexcPdLjFv6ymMeta || {},
    alias: indexcPdLjFv6ymMeta?.alias || [],
    redirect: indexcPdLjFv6ymMeta?.redirect,
    component: () => import("/workspace/pages/providers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgNGqByi03Meta?.name ?? "sb-auth-confirm",
    path: indexEgNGqByi03Meta?.path ?? "/sb-auth/confirm",
    meta: indexEgNGqByi03Meta || {},
    alias: indexEgNGqByi03Meta?.alias || [],
    redirect: indexEgNGqByi03Meta?.redirect,
    component: () => import("/workspace/pages/sb-auth/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexgPppCmCO0TMeta?.name ?? "sb-auth-login",
    path: indexgPppCmCO0TMeta?.path ?? "/sb-auth/login",
    meta: indexgPppCmCO0TMeta || {},
    alias: indexgPppCmCO0TMeta?.alias || [],
    redirect: indexgPppCmCO0TMeta?.redirect,
    component: () => import("/workspace/pages/sb-auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexzSnIsKkEd9Meta?.name ?? "signup",
    path: indexzSnIsKkEd9Meta?.path ?? "/signup",
    meta: indexzSnIsKkEd9Meta || {},
    alias: indexzSnIsKkEd9Meta?.alias || [],
    redirect: indexzSnIsKkEd9Meta?.redirect,
    component: () => import("/workspace/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: index9xmF4TLFhBMeta?.name ?? "terms-of-use",
    path: index9xmF4TLFhBMeta?.path ?? "/terms-of-use",
    meta: index9xmF4TLFhBMeta || {},
    alias: index9xmF4TLFhBMeta?.alias || [],
    redirect: index9xmF4TLFhBMeta?.redirect,
    component: () => import("/workspace/pages/terms-of-use/index.vue").then(m => m.default || m)
  }
]