import { defineStore } from "pinia";
import _orderBy from "lodash/orderBy";

const state = () => ({
    insurance: new Map(),
    sort_by: "created_on._seconds",
    sort_order: "desc",
    isLoaded: null,
});

const getters = {
    sortedInsurance: (state) => {
        let sortedInsurance = _orderBy(
            [...state.insurance.values()],
            [state.sort_by],
            [state.sort_order]
        );
        return sortedInsurance;
    },
    count(state): Boolean {
        return state.insurance.size;
    },
    addresses(state): any {
        return state.insurance.addresses;
    },
    getInsuranceById: (state) => {
        return (id) => state.insurance.get(id);
    },
};

const actions = {
    async loadInsurance() {
        const authStore = useAuthStore();
        const client_id = authStore.client.id;

        try {
            const { data } = await useServerFetch(
                `/api/v3/client/${client_id}/insurance`,
                {
                    initialCache: false,
                }
            );
            this.insurance.clear();

            if (data.value) {
                const arr: any = data.value.insurance;
                arr.forEach((object: any) => {
                    this.insurance.set(object.id, object);
                });
            }
            this.isLoaded = true;
        } catch (error) {
            let message;
            if (error instanceof Error) message = error.message;
            else message = String(error);
        }
    },
};

export const useClientInsuranceStore = defineStore("clientInsuranceStore", {
    state,
    getters,
    actions,
});
