import { defineStore } from "pinia";

const state = () => ({
    client: null,
});

const getters = {
    clientId: (state) => {
        return state.client?.id;
    },
    clientInsurance: (state) => {
        return state.client?.insurance || null;
    },
    clientPartners: (state) => {
        return state.client?.partners || null;
    },
    insurance_id: (state) => {
        return state.client?.insurance_id || null;
    },
    stripeCustomerId: (state) => {
        const config = useRuntimeConfig();

        if (
            config.public.STRIPE_LIVEMODE &&
            state.client?.stripe_customer?.live
        ) {
            return state.client?.stripe_customer?.live;
        } else if (
            !config.public.STRIPE_LIVEMODE &&
            state.client?.stripe_customer?.test
        ) {
            return state.client?.stripe_customer?.test;
        } else return null;
    },
    stripePaymentMethodId: (state) => {
        const config = useRuntimeConfig();
        if (
            config.public.STRIPE_LIVEMODE &&
            state.client?.stripe_payment_method?.live
        ) {
            return state.client?.stripe_payment_method?.live;
        } else if (
            !config.public.STRIPE_LIVEMODE &&
            state.client?.stripe_payment_method?.test
        ) {
            return state.client?.stripe_payment_method?.test;
        } else return null;
    },
};

const actions = {};

export const useClientStore = defineStore("clientStore", {
    state,
    getters,
    actions,
});
