import _groupBy from "lodash/groupBy";

export const useAccountBillingStore = defineStore("accountBillingStore", () => {
    // State
    const paymentItemsMap = ref(new Map());
    const paymentMethodsMap = ref(new Map());
    const paymentIntentsMap = ref(new Map());

    const selectedPaymentIntent: Ref<string | null> = ref(null);

    const modalPayBalanceIsActive = ref(false);
    const modalAddCardPaymentMethodIsActive = ref(false);

    const setupIntentClientSecret = ref(null);

    // Getters
    const primaryPaymentMethod = computed(() => {
        const accountStore = useAccountStore();
        const { account } = storeToRefs(accountStore);
        return (
            paymentMethodsMap.value.get(
                account.value?.stripe_payment_method_id
            ) || null
        );
    });
    const paymentItems = computed(() => {
        return [...paymentItemsMap.value.values()];
    });
    const paymentMethods = computed(() => {
        return [...paymentMethodsMap.value.values()];
    });

    const activePaymentIntent = computed(() => {
        return paymentIntentsMap.value.get(selectedPaymentIntent.value) || null;
    });

    const paymentItemsByCurrency = computed(() => {
        const groupedPaymentItems = _groupBy(
            [...paymentItems.value.values()],
            "currency"
        );
        const currencyPaymentsArray: any[] = [];
        for (const [key, value] of Object.entries(groupedPaymentItems)) {
            currencyPaymentsArray.push({
                currency: key,
                amount: sumAmount(value),
                paymentItems: value,
                payment_items: value.map((item) => item.id),
            });
        }
        console.log("currencyPaymentsArray: ", currencyPaymentsArray);
        return currencyPaymentsArray;
    });

    // Actions
    async function fetchAccountPaymentItems(reload?: boolean) {
        console.log("fetchAccountPaymentItems reload: ", reload);
        const userStore = useUserStore();
        const accountId = userStore.accountId;

        if (paymentItemsMap.value.size && !reload) return;

        const data: any[] = await $fetchWithToken(
            `/api/v4/account/${accountId}/payment-items/open`
        );
        console.log("fetchAccountOpenPayments data: ", data);
        paymentItemsMap.value.clear();
        data.forEach((payment: any) => {
            paymentItemsMap.value.set(payment.id, payment);
        });
    }

    async function fetchStripePaymentMethods(reload?: boolean) {
        console.log("fetchStripePaymentMethods");
        const userStore = useUserStore();
        const accountId = userStore.accountId;

        if (paymentMethodsMap.value.size && !reload) return;

        const data: any[] = await $fetchWithToken(
            `/api/v4/account/${accountId}/payment-methods`
        );
        console.log("fetchStripePaymentMethods data: ", data);
        paymentMethodsMap.value.clear();
        data.forEach((method: any) => {
            paymentMethodsMap.value.set(method.id, method);
        });
    }

    async function loadPaymentIntent(payment_items: string[]) {
        console.log("loadPaymentIntent payment: ", payment_items);

        const userStore = useUserStore();
        const accountId = userStore.accountId;
        const paymentItemsString = payment_items.sort().toString();

        try {
            // 1. Check if PaymentIntent with paymentItems already exists in store. If so, reload from it from Stripe.
            const existingPaymentIntent = [
                ...paymentIntentsMap.value.values(),
            ].find(
                (item) => item.metadata?.payment_items == paymentItemsString
            );

            if (existingPaymentIntent) {
                selectedPaymentIntent.value = existingPaymentIntent.id;
                const { paymentIntent: existingPaymentIntentData }: any =
                    await $fetchWithToken(
                        `/api/v4/account/${accountId}/stripe/payment-intents/${existingPaymentIntent.id}`
                    );
                paymentIntentsMap.value.set(
                    existingPaymentIntentData.id,
                    existingPaymentIntentData
                );
                return;
            }

            // 2. Create PaymentIntent and store Client Secret to store
            const data = await $fetchWithToken(
                `/api/v4/account/${accountId}/stripe/payment-intents`,
                {
                    method: "post",
                    body: {
                        paymentItems: payment_items,
                    },
                }
            );
            const paymentIntentData: any = data;
            paymentIntentsMap.value.set(
                paymentIntentData.id,
                paymentIntentData
            );
            selectedPaymentIntent.value = paymentIntentData.id;
        } catch (error) {
            console.log("loadPaymentIntent error: ", error);
        }
    }

    async function fetchStripePaymentIntentById(id: string, reload?: boolean) {
        console.log("fetchStripePaymentIntentById");

        if (paymentIntentsMap.value.has(id) && !reload) return;

        const data: any[] = await $fetchWithToken(
            `/api/v4/stripe/payment-intents/${id}`
        );
        console.log("fetchStripePaymentMethods data: ", data);
        const paymentIntent = data.paymentIntent;
        paymentIntentsMap.value.set(paymentIntent.id, paymentIntent);
    }

    async function refreshStripePaymentIntentById(id: string) {
        console.log("refreshStripePaymentIntentById");

        const userStore = useUserStore();
        const accountId = userStore.accountId;

        const data: any = await $fetchWithToken(
            `/api/v4/account/${accountId}/stripe/payment-intents/${id}`,
            {
                method: "post",
            }
        );
        console.log("refreshStripePaymentIntentById data: ", data);
        const paymentIntent = data.paymentIntent;
        paymentIntentsMap.value.set(paymentIntent.id, paymentIntent);
    }

    async function loadSetupIntentClientSecret() {
        console.log("loadSetupIntent");

        const userStore = useUserStore();
        const accountId = userStore.accountId;

        const { client_secret }: any = await $fetchWithToken(
            `/api/v4/account/${accountId}/stripe/setup-intent`
        );
        console.log("client_secret: ", client_secret);
        setupIntentClientSecret.value = client_secret;
    }

    function $reset() {
        paymentItemsMap.value.clear();
        paymentMethodsMap.value.clear();
        paymentIntentsMap.value.clear();
    }

    return {
        selectedPaymentIntent,
        setupIntentClientSecret,

        modalPayBalanceIsActive,
        modalAddCardPaymentMethodIsActive,

        primaryPaymentMethod,
        paymentItems,
        paymentMethods,

        paymentItemsByCurrency,
        activePaymentIntent,

        fetchAccountPaymentItems,
        fetchStripePaymentMethods,
        fetchStripePaymentIntentById,
        refreshStripePaymentIntentById,
        loadPaymentIntent,
        loadSetupIntentClientSecret,
        $reset,
    };
});

function sumAmount(arr: any[]) {
    return arr.reduce(
        (accumulator: any, currentValue: any) =>
            accumulator + currentValue.amount,
        0
    );
}
