import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/workspace/middleware/analytics.global.ts";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "authenticated-admin": () => import("/workspace/middleware/authenticated-admin.ts"),
  "authenticated-client": () => import("/workspace/middleware/authenticated-client.ts"),
  "authenticated-provider": () => import("/workspace/middleware/authenticated-provider.ts"),
  "onboarding-needs-insurance-plan": () => import("/workspace/middleware/onboarding-needs-insurance-plan.ts"),
  "onboarding-needs-insurance": () => import("/workspace/middleware/onboarding-needs-insurance.ts"),
  "onboarding-progress": () => import("/workspace/middleware/onboarding-progress.ts"),
  onboarding: () => import("/workspace/middleware/onboarding.ts"),
  "partner-redirect": () => import("/workspace/middleware/partner-redirect.ts")
}