// Is a composable because uses other composables (configures useFetch)

import type { UseFetchOptions } from "nuxt/app";
import { defu } from "defu";

export const useServerFetch = async (
    url: string | (() => string),
    options: UseFetchOptions<T> = {}
) => {
    const { getFirebaseIdToken } = useFirebaseAuth();

    const defaults: UseFetchOptions<T> = {
        async onRequest({ request, options }) {
            await getFirebaseIdToken();
        },
    };

    const params = defu(options, defaults);

    return useFetch(url, params);
};
